export const priceList = [
  {
    idPrice: 1,
    idArticle: "COFRESA",
    price: 2598,
    idPriceList: 1,
    date: "2021-09-17 17:19:09.707",
    userId: 2,
  },
  {
    idPrice: 2,
    idArticle: "COFRESA",
    price: 2598,
    idPriceList: 4,
    date: "2021-09-17 17:19:09.88",
    userId: 2,
  },
  {
    idPrice: 3,
    idArticle: "COFRESA",
    price: 2598,
    idPriceList: 3,
    date: "2021-09-17 17:19:10.05",
    userId: 2,
  },
  {
    idPrice: 4,
    idArticle: "COFRESA",
    price: 2598,
    idPriceList: 2,
    date: "2021-09-17 17:19:10.143",
    userId: 2,
  },
  {
    idPrice: 5,
    idArticle: "COMANGO",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:28:28.41",
    userId: 2,
  },
  {
    idPrice: 6,
    idArticle: "COMANGO",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:28:28.47",
    userId: 2,
  },
  {
    idPrice: 7,
    idArticle: "COMANGO",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:28:28.533",
    userId: 2,
  },
  {
    idPrice: 8,
    idArticle: "COMANGO",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:28:28.597",
    userId: 2,
  },
  {
    idPrice: 9,
    idArticle: "YAKA",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:29:44.977",
    userId: 2,
  },
  {
    idPrice: 10,
    idArticle: "YAKA",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:29:45.04",
    userId: 2,
  },
  {
    idPrice: 11,
    idArticle: "YAKA",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:29:45.1",
    userId: 2,
  },
  {
    idPrice: 12,
    idArticle: "YAKA",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:29:45.163",
    userId: 2,
  },
  {
    idPrice: 13,
    idArticle: "MORINGA",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:31:03.633",
    userId: 2,
  },
  {
    idPrice: 14,
    idArticle: "MORINGA",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:31:03.697",
    userId: 2,
  },
  {
    idPrice: 15,
    idArticle: "MORINGA",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:31:03.743",
    userId: 2,
  },
  {
    idPrice: 16,
    idArticle: "MORINGA",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:31:03.82",
    userId: 2,
  },
  {
    idPrice: 17,
    idArticle: "JUGOVERDE",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:32:08.687",
    userId: 2,
  },
  {
    idPrice: 18,
    idArticle: "JUGOVERDE",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:32:08.75",
    userId: 2,
  },
  {
    idPrice: 19,
    idArticle: "JUGOVERDE",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:32:08.81",
    userId: 2,
  },
  {
    idPrice: 20,
    idArticle: "JUGOVERDE",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:32:08.89",
    userId: 2,
  },
  {
    idPrice: 21,
    idArticle: "FORCE",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:33:04.13",
    userId: 2,
  },
  {
    idPrice: 22,
    idArticle: "FORCE",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:33:04.193",
    userId: 2,
  },
  {
    idPrice: 23,
    idArticle: "FORCE",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:33:04.257",
    userId: 2,
  },
  {
    idPrice: 24,
    idArticle: "FORCE",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:33:04.32",
    userId: 2,
  },
  {
    idPrice: 25,
    idArticle: "ANCESTRAL",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:36:34.27",
    userId: 2,
  },
  {
    idPrice: 26,
    idArticle: "ANCESTRAL",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:36:34.33",
    userId: 2,
  },
  {
    idPrice: 27,
    idArticle: "ANCESTRAL",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:36:34.393",
    userId: 2,
  },
  {
    idPrice: 28,
    idArticle: "ANCESTRAL",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:36:34.457",
    userId: 2,
  },
  {
    idPrice: 29,
    idArticle: "LUXURY",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:37:34.003",
    userId: 2,
  },
  {
    idPrice: 30,
    idArticle: "LUXURY",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:37:34.05",
    userId: 2,
  },
  {
    idPrice: 31,
    idArticle: "LUXURY",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:37:34.11",
    userId: 2,
  },
  {
    idPrice: 32,
    idArticle: "LUXURY",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:37:34.19",
    userId: 2,
  },
  {
    idPrice: 33,
    idArticle: "CAMUCAMU",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:38:53.237",
    userId: 2,
  },
  {
    idPrice: 34,
    idArticle: "CAMUCAMU",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:38:53.3",
    userId: 2,
  },
  {
    idPrice: 35,
    idArticle: "CAMUCAMU",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:38:53.36",
    userId: 2,
  },
  {
    idPrice: 36,
    idArticle: "CAMUCAMU",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:38:53.423",
    userId: 2,
  },
  {
    idPrice: 37,
    idArticle: "PROTEINAMUJER",
    price: 1399,
    idPriceList: 1,
    date: "2021-09-18 15:41:06.2",
    userId: 2,
  },
  {
    idPrice: 38,
    idArticle: "PROTEINAMUJER",
    price: 1299,
    idPriceList: 4,
    date: "2021-09-18 15:41:06.26",
    userId: 2,
  },
  {
    idPrice: 39,
    idArticle: "PROTEINAMUJER",
    price: 1199,
    idPriceList: 3,
    date: "2021-09-18 15:41:06.323",
    userId: 2,
  },
  {
    idPrice: 40,
    idArticle: "PROTEINAMUJER",
    price: 999,
    idPriceList: 2,
    date: "2021-09-18 15:41:06.387",
    userId: 2,
  },
  {
    idPrice: 41,
    idArticle: "NAVICK",
    price: 1000,
    idPriceList: 1,
    date: "2021-09-18 15:41:06.2",
    userId: 2,
  },
  {
    idPrice: 42,
    idArticle: "NAVICK",
    price: 1000,
    idPriceList: 4,
    date: "2021-09-18 15:41:06.26",
    userId: 2,
  },
  {
    idPrice: 43,
    idArticle: "NAVICK",
    price: 1000,
    idPriceList: 3,
    date: "2021-09-18 15:41:06.323",
    userId: 2,
  },
  {
    idPrice: 44,
    idArticle: "NAVICK",
    price: 1000,
    idPriceList: 2,
    date: "2021-09-18 15:41:06.387",
    userId: 2,
  },
];
