import { Box, Grid, Typography, Button } from "@material-ui/core";
import deleteRoutesStyles from "./DeleteRoute.styles";
import { deleteRouteById } from "../../../api/ApiRoutes";
import { useState } from "react";
import AnimationContent from "../../sharedComponents/AnimationContent";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import DeleteAnimation from "../../../assets/lottieFiles/DeleteAnimation.json";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../../store/slices/clients";

export const DeleteRoute = (props) => {
  const { handleCloseModalDeleteRoute, idRoute } = props;
  const classes = deleteRoutesStyles();
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const handleDeleting = () => {
    setTimeout(() => {
      setDeleting(false);
      setDeleted(true);
    }, 2000);

    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };
  const delRoute = async () => {
    try {
      setDeleting(true);
      const result = await deleteRouteById(idRoute, accessToken);
      if (result.data.statusCode === 200) {
        handleDeleting();
      } else {
        setDeleting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.mainContainer}>
      {!deleting && !deleted ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.deleteRouteTitle}>
              ¿Estás seguro de Eliminar esta Ruta?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.deleteRouteSubTitle}>
              La Ruta se Eliminará permanentemente
            </Typography>
          </Grid>
          <Grid container className={classes.subtitleBox}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button
                item
                className={classes.cancelDeleteRouteButton}
                onClick={handleCloseModalDeleteRoute}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button
                item
                className={classes.deleteRouteButton}
                onClick={delRoute}
              >
                Eliminar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          display="center"
          alignItems="center"
          justifyContent="center"
        >
          {deleting && (
            <Box>
              <AnimationContent
                animationFile={loadingAnimation}
                height={150}
                width={150}
              />
              <Typography
                variant="h3"
                align="center"
                className={classes.animationTitle}
              >
                Eliminando Ruta
              </Typography>
              <Typography
                variant="h6"
                align="center"
                className={classes.animationTitle}
              >
                Por favor, no cierres la pantalla
              </Typography>
            </Box>
          )}
          {deleted && (
            <Box>
              <AnimationContent
                className={classes.animationStyle}
                animationFile={DeleteAnimation}
                height={150}
                width={150}
              />
              <Typography
                variant="h3"
                align="center"
                className={classes.animationTitle}
              >
                Ruta Eliminada
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Box>
  );
};

// export default CreateRoute;
