import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
//Slices
import userReducer from "./slices/user";
import invoiceReducer from "./slices/invoices";
import clientsReducer from "./slices/clients";
import routesReducer from "./slices/routes";
import productsReducer from "./slices/products";
import paymentsReducer from "./slices/payments";
const persistConfig = {
  key: "navick",
  storage,
};

const reducers = combineReducers({
  users: userReducer,
  invoices: invoiceReducer,
  clients: clientsReducer,
  routes: routesReducer,
  products: productsReducer,
  payments: paymentsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const persist = persistedReducer;

export default configureStore({
  reducer: persist,
  middleware: [thunk],
});
