import { makeStyles } from "@material-ui/core/styles";

const RemainingPaymentStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 50,
    width: "90%",
  },
  title: {
    marginTop: 50,
    color: theme.palette.primary.dark,
  },
  subtitle: {
    marginTop: 20,
    color: theme.palette.primary.dark,
  },
  dataTableContainer: {
    marginTop: 20,
    width: 400,
    overflow: "auto",
    textAlign: "center",
  },
  notListedClients: {
    marginTop: 10,
    color: theme.palette.primary.dark,
  },
  printButton: {
    width: 200,
    marginLeft: 50,
    marginTop: 20,
  },
  searchBarInputfield: {
    width: 400,
    marginTop: 20,
    backgroundColor: theme.palette.primary.contrastText,
    border: "transparent",
    borderRadius: 10,
    "&:hover": {
      borderColor: "transparent",
    },
    marginBottom: 30,
  },routeDropdown:{
    marginTop:20,
    marginBottom:20
  },
  routeDropdownContainer:{
    width:200
  }
}));

export default RemainingPaymentStyles;
