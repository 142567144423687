import React from "react";
import Modal from "@material-ui/core/Modal";
import ModalComponentStyles from "./ModalComponent.styles";
import Backdrop from "@material-ui/core/Backdrop";
import { Fade } from "@material-ui/core";

const ModalComponent = (props) => {
  const { handleClose, open, body } = props;
  const classes = ModalComponentStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="title-modal"
        aria-describedby="modal-component"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>{body}</Fade>
      </Modal>
    </div>
  );
};

export default ModalComponent;
