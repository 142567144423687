import { makeStyles } from "@material-ui/core/styles";

const CancelInvoiceByIdModalStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.white,
    width: 400,
    height: 520,
    borderRadius: 10,
    padding: 10,
  },
  closeIcon: {
    display: "flex",
    float: "right",
    fontSize: 25,
    cursor: "pointer",
    marginTop:-20,
  },
  cancelInvoiceTitle: {
    marginTop: 20,
    color: theme.palette.primary.dark,
  },
  idInvoiceHeader: {
    marginTop: 20,
    color: theme.palette.primary.dark,
  },
  formContainer: {
    marginTop: 30,
  },
  amountField: {
    marginTop: 10,
    width: 200,
  },
  cancelButtonContainer: {
    marginTop: 20,
  },
  cancelButton: {
    width: 200,
    height: 45,
    borderRadius: 10,
  },
  loadinHeader:{
    marginBottom:10,
    color:theme.palette.primary.dark
  }
}));

export default CancelInvoiceByIdModalStyles;
