import { makeStyles } from "@material-ui/core/styles";

const PricesCardStyles = makeStyles((theme) => ({
  CardContainer: {
    backgroundColor: theme.palette.background.paper,
    width: 520,
    height: 80,
    borderRadius: 10,
    marginTop: 20,
  },
  productDescription: {
    padding: 15,
    marginLeft: 10,
    marginTop: 5,
  },
  productPrice: {
    fontSize: 10,
    marginLeft: 25,
    marginTop: -10,
    color: theme.palette.primary.text_dark_disabled,
  },
  icon: {
    fontSize: 14,
    marginLeft: 5,
    marginRight: 5,
  },
  iconsContainer: {
    marginTop: 20,
    marginLeft: 30,
    display: "flex",
    flexDirection: "row",
  },
  productPriceBig: {
    marginTop: 30,
    marginLeft: 30,
    marginRight:20
  },
  amount: {
    marginTop: 8,
    fontSize: 12,
  },
  deleteIcon: {
    marginTop: 13,
    marginLeft: 70,
  },
  editIcon: {
    marginTop: 13,
    marginLeft: 80,
  },
  inputPrice:{
    textAlign:"center"
  }
}));

export default PricesCardStyles;
