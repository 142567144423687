import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../constants/styleConstants";
const invoiceStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 80,
    width: "100%",
  },
  titleContainer: {
    color: theme.palette.primary.dark,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_MEDIUM,
    fontSize: 36,
    marginTop: 70,
    marginBottom: 80,
  },
  invoiceMonthIcon: {
    width: 80,
    height: 80,
  },
  invoiceCardsInfo: {
    marginTop: 50,
  },
  ///
  linkItems: {
    textDecoration: "none",
    padding: 10,
    marginLeft: 20,
    fontSize: 16,
    color: theme.palette.primary.dark,
    width: 120,
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s",
    },
  },
  linkItem: {
    textDecoration: "none",
    padding: 10,
    fontSize: 16,
    color: theme.palette.primary.dark,
    width: 120,
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s",
    },
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.white,
    borderRadius: 20,
    width: 120,
    animation: "width 1s",
  },
  menubarItems: {
    marginBottom: 80,
  },
  searchBarContainer: {
    backgroundColor: theme.palette.primary.apple_green,
    height: 80,
    width: 1200,
    clear: "both",
    borderRadius: 10,
    marginTop: -40,
    marginBottom: 20,
  },
  searchBarInputfield: {
    width: "95%",
    backgroundColor: theme.palette.primary.contrastText,
    border: "transparent",
    borderRadius: 10,
    "&:hover": {
      borderColor: "transparent",
    },
  },
  createInvoiceButton: {
    marginLeft: 200,
    borderRadius: 50,
    height: 35,
    backgroundColor: theme.palette.secondary.main,
    transition: "all 700ms",
  },
  cancelInvoiceButtonOpen: {
    borderRadius: 50,
    height: 40,
    width: 130,
    backgroundColor: theme.palette.error.main,
    transition: "all 700ms",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  createInvoiceButtonOpen: {
    borderRadius: 50,
    height: 40,
    width: 190,
    marginLeft: 50,
    backgroundColor: theme.palette.primary.main,
    transition: "all 700ms",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  animationContainer: {
    height: 450,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding:20,
    borderRadius:10
  },
}));

export default invoiceStyles;
