import { Box } from "@material-ui/core";
import React from "react";
import {
  withScriptjs,
  GoogleMap,
  withGoogleMap,
  Marker,
} from "react-google-maps";
const Map = (props) => {
  const { isMarkerShown, lat, long, arrayMarkers } = props;
  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={
          !isMarkerShown
            ? { lat: 19.9858929115869, lng: -102.28538143146716 }
            : { lat: lat, lng: long }
        }
      >
        {isMarkerShown && <Marker position={{ lat: lat, lng: long }} />}
        {arrayMarkers &&
          arrayMarkers.map((el, i) => (
            <Box>
              <Marker
                key={i}
                position={{
                  lat: parseFloat(el.latitude),
                  lng: parseFloat(el.longitude),
                }}
              />
            </Box>
          ))}
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(Map));
