import axios from "axios";
const { REACT_APP_NAVICK_AUTH } = process.env;

export const loginUser = (username, password) => {
  const payload = {
    username,
    password,
  };
  const request = {
    host: `${REACT_APP_NAVICK_AUTH}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_AUTH}/login`,
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
