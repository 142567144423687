import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AvatarImage from "../../assets/images/LoginImage.png";
import CenteredContent from "../../components/sharedComponents/CenteredContent";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LoginPageStyles from "./LoginPage.styles";
import LockIcon from "@material-ui/icons/Lock";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { loginUser } from "../../api/apiAuth";
import { setIsAuth, setLogedUserInformation } from "../../store/slices/user";
import { setAccessToken } from "../../store/slices/clients";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
const LoginPage = () => {
  const classes = LoginPageStyles();
  //Password input
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorAuth, setErrorAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogin = () => {
    setLoading(true);
    loginUser(user, password)
      .then(({ data }) => {
        if (data.statusCode === 200) {
          if (data.result.idUserType === 2 && data.result.status === 1) {
            // if the user is active and admin
            error && setError(false);
            dispatch(setLogedUserInformation(data.result));
            dispatch(setIsAuth(true));
            dispatch(setAccessToken(data.result.token));
            history.push(ROUTES_CONSTANTS.ADMIN);
          } else {
            setLoading(false);
            setError(false);
            setErrorAuth(true);
          }
        } else {
          setLoading(false);
          setError(true);
          setErrorAuth(false);
        }
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
          setLoading(false);
          setError(true);
        } else {
          setLoading(false);
          setError(true);
        }
      });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();

        handleLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleLogin]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        className={classes.imageLoginContainer}
      >
        <Typography variant="h1" align="left" className={classes.navickLogo}>
          Navick
        </Typography>
        <CenteredContent direction="column">
          <Hidden mdDown>
            <Typography variant="h1" className={classes.LoginSlogan}>
              Administra, consulta y actualiza de una mejor manera toda tu
              información.
            </Typography>
          </Hidden>
          <img src={AvatarImage} alt="avatar" className={classes.avatarImage} />
        </CenteredContent>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <form>
          <CenteredContent direction="column">
            <Hidden mdDown>
              <Typography variant="h1" className={classes.navickTitle}>
                Navick
              </Typography>
            </Hidden>
            <Typography variant="h1" className={classes.iniciarSesionText}>
              Ingresa a tu cuenta
            </Typography>
            <TextField
              variant="outlined"
              label="Usuario"
              className={classes.inputUser}
              placeholder="Ingresa tu usuario"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="contrasena"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              variant="outlined"
              label="Contraseña"
              id="contrasena"
              autoComplete="off"
              className={classes.inputPassword}
              type={showPassword ? "text" : "password"}
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="contrasena"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="contrasena"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <LockIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <FormHelperText error={error}>
              {error && (
                <Typography
                  variant="subtitle1"
                  className={classes.errorMessage}
                >
                  El usuario o el password son incorrectos
                </Typography>
              )}
            </FormHelperText>
            <FormHelperText error={error}>
              {errorAuth && (
                <Typography
                  variant="subtitle1"
                  className={classes.errorMessage}
                >
                  No tienes los permisos para acceder
                </Typography>
              )}
            </FormHelperText>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonLogin}
              onClick={handleLogin}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  style={{ color: "white", marginRight: 10 }}
                />
              )}
              {loading ? "Iniciando" : "Continuar"}
            </Button>
          </CenteredContent>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
