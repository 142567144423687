import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import DrawerMenu from "../../components/DrawerMenu/Index";
import RemainingPaymentStyles from "./RemainingPayments.style";
import { getClientsRaminingPayment } from "../../api/apiClients";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientRemaining,
  removeClientRemaining,
  resetClientListToPrint,
  selectAccessToken,
  selectClientListToPrintDetails,
  selectClientsRemaining,
  setClientListToPrintDetails,
} from "../../store/slices/clients";
import ClientCard from "../../components/sharedComponents/ClientCard/ClientCard";
import { BsSearch } from "react-icons/bs";
import DataTableClientsInfo from "./DataTableClientsInfo";
import { selectAvailableRoutes } from "../../store/slices/routes";

import PaymentCardToPrint from "../../components/sharedComponents/PaymentCardsToPrint";
import { useEffect } from "react";
// import { GiAk47 } from "react-icons/gi";

const RemainingPayments = () => {
  const classes = RemainingPaymentStyles();
  const dispatch = useDispatch();
  const clientsListed = useSelector(selectClientsRemaining);
  const [selectedRoute, setSelectedRoute] = useState("all");
  const [filteredClients, setFilteredClients] = useState([]);
  // const [printAll, setPrintAll] = useState(false);
  const clientsInfo = useSelector(selectClientListToPrintDetails);
  const accesToken = useSelector(selectAccessToken);
  const availableRoutes = useSelector(selectAvailableRoutes);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);

  const [newOrder, setNewOrder] = useState([]);

  useEffect(() => {
    getClientsRaminingPayment(accesToken).then((res) => {
      setFilteredClients(res.data.result);
      // dispatch(setClientListToPrintDetails(res.data.result));
    });
  }, [accesToken, dispatch]);

  const handleSelectedRoute = (routeName) => {
    setSelectedRoute(routeName);
    dispatch(resetClientListToPrint());
    getClientsRaminingPayment(accesToken).then((res) => {
      const clients = res.data.result;
      if (routeName === "all") {
        setFilteredClients(clients);
        dispatch(setClientListToPrintDetails(clients));
      } else {
        setFilteredClients(
          clients.filter((client) => client.route_name === routeName)
        );
        dispatch(
          setClientListToPrintDetails(
            clients.filter((client) => client.route_name === routeName)
          )
        );
      }
    });
  };

  const addClientToList = (client) => {
    dispatch(addClientRemaining(client));
  };

  const removeClientToList = (clientId) => {
    dispatch(removeClientRemaining(clientId));
  };

  const printRef = React.useRef();

  const handleCreateCards = () => {
    // setPrintAll(false);
    setIsReadyToPrint(true);
  };

  useEffect(() => {
    if (isReadyToPrint === false) return;

    window.print();
  }, [isReadyToPrint]);

  window.addEventListener("afterprint", (event) => {
    setIsReadyToPrint(false);
    dispatch(resetClientListToPrint());
  });

  const handlePrintAllInvoices = async () => {
    // setPrintAll(true);

    let arrayClients = [];
    let arrayOnlyFour = [];

    filteredClients.forEach((item, index) => {
      if (index % 4 === 0) {
        arrayOnlyFour = [];
        arrayOnlyFour.push(item);
        arrayClients.push(arrayOnlyFour);
        return;
      }
      arrayOnlyFour.push(item);
    });
    setNewOrder(arrayClients);

    setIsReadyToPrint(true);
  };

  const handleSearch = (field_name) => {
    let filteredClients = !field_name
      ? clientsInfo
      : clientsInfo.filter((client) =>
          client.name.toLowerCase().includes(field_name.toLowerCase())
        );
    setFilteredClients(filteredClients);
  };

  useEffect(() => {
    const crazyThings = () => {
      let arrayClients = [];
      let arrayOnlyFour = [];

      clientsListed.forEach((item, index) => {
        if (index % 4 === 0) {
          arrayOnlyFour = [];
          arrayOnlyFour.push(item);
          arrayClients.push(arrayOnlyFour);
          return;
        }
        arrayOnlyFour.push(item);
      });
      setNewOrder(arrayClients);
    };
    crazyThings();
  }, [clientsListed]);

  return (
    <>
      {isReadyToPrint ? (
        newOrder.map((stylesheet, styleIndex) => {
          return (
            <div className="stylesheet-a4" key={styleIndex}>
              <div className="stylesheet-a4__container">
                {stylesheet.map((ficha) => {
                  return <PaymentCardToPrint data={ficha} />;
                })}
              </div>
            </div>
          );
        })
      ) : (
        // <div className="stylesheet-a4">
        //   {printAll
        //     ? filteredClients.map((clientF, clientIndex) => {
        //       return <PaymentCardToPrint data={clientF} />;
        //     })
        //     : clientsListed.map((clientL, clientIndex) => {
        //       return <PaymentCardToPrint data={clientL} />;
        //     })}
        // </div>
        <DrawerMenu>
          <Box className={classes.mainContainer}>
            <Typography variant="h4" className={classes.title}>
              Impresión de fichas
            </Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Busqueda por cliente
            </Typography>

            <TextField
              name="searchbar"
              variant="outlined"
              placeholder="Buscar cliente por nombre"
              onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="contrasena" edge="start">
                      <BsSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className={classes.searchBarInputfield}
            />
            <Typography variant="subtitle2" className={classes.subtitle}>
              Busqueda por ruta
            </Typography>

            <FormControl className={classes.routeDropdownContainer}>
              <Select
                className={classes.routeDropdown}
                variant="outlined"
                value={selectedRoute}
                onChange={(e) => handleSelectedRoute(e.target.value)}
                inputProps={{
                  name: "route",
                  id: "route-name",
                }}
              >
                <MenuItem value="all">Todos</MenuItem>
                {availableRoutes.length > 0 ? (
                  availableRoutes.map((route) => (
                    <MenuItem key={route.id_route} value={route.description}>
                      {route.description}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="0">No hay rutas disponibles</MenuItem>
                )}
              </Select>
            </FormControl>

            <Grid container>
              <Grid item xs={5} style={{ marginBottom: 100 }}>
                <DataTableClientsInfo
                  rows={filteredClients}
                  addClientToListHandler={addClientToList}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <Typography variant="h3"> Clientes seleccionados</Typography>
                {clientsListed.length > 0 ? (
                  clientsListed.map((client) => (
                    <Box key={client.id_client}>
                      <ClientCard
                        key={client.id_client}
                        handleRemove={removeClientToList}
                        client={client}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="subtitle2"
                    className={classes.notListedClients}
                  >
                    Sin clientes en lista
                  </Typography>
                )}

                <Box display="flex" flexDirection="column">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.printButton}
                    onClick={() => handleCreateCards(printRef)}
                    disabled={clientsListed.length > 0 ? false : true}
                  >
                    Imprimir Fichas
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.printButton}
                    onClick={() => handlePrintAllInvoices(printRef)}
                    disabled={clientsListed.length >= 1}
                  >
                    Imprimir todas las fichas
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DrawerMenu>
      )}
    </>
  );
};

export default RemainingPayments;
