import { makeStyles } from "@material-ui/core/styles";
const ClientDetailsInformationStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 150,
    marginTop: -200,
  },
  headerSection: {
    marginTop: 50,
    marginBottom: 5,
  },
  headerTitleSection: {
    color: theme.palette.primary.main,
    marginTop: 50,
  },
  clientName: {
    color: theme.palette.primary.text_dark_disabled,
  },
  clientInformationContainer: {
    width: 1250,
  },
  closeIcon: {
    float: "right",
    marginRight: 120,
    fontSize: 18,
    marginTop: 10,
    cursor: "pointer",
  },

  
}));

export default ClientDetailsInformationStyles;
