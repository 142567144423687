import React from "react";
import "../../../assets/css/PaymentCardStyles.css";
const PaymentCardToPrint = (props) => {
  const {
    id_client,
    name,
    route_name,
    address,
    zip_code,
    personal_phonenumber,
    home_phonenumber,
    pay_days,
    comments,
    id_invoice,
    remaining_payment,
  } = props.data;

  return (
    <div className="payment-card">
      <div className="payment-card__container">
        <div className="payment-card__top">
          <p className="payment-card__id">
            <strong>ID Cliente:</strong>
            <span>{id_client}</span>
          </p>
          <div className="payment-card__info">
            <p>
              <strong>ID de factura:</strong>
              <span>{id_invoice}</span>
            </p>
            <p>
              <strong>Ruta:</strong>
              <span>{route_name}</span>
            </p>
            <p>
              <strong>Nombre:</strong>
              <span>{name}</span>
            </p>
            <p>
              <strong>Dirección:</strong>
              <span>{address}</span>
            </p>
          </div>
          <div className="payment-card__personal">
            <p>
              <strong>Cel:</strong>
              <span>{personal_phonenumber}</span>
            </p>
            <p>
              <strong>Tel:</strong>
              <span>{home_phonenumber}</span>
            </p>
            <p>
              <strong>C.P.:</strong>
              <span>{zip_code}</span>
            </p>
          </div>
          <div className="payment-card__payments">
            <p>
              <strong>Saldo:</strong>
              <span>$ {remaining_payment}</span>
            </p>
            <p>
              <strong>Dias de pago:</strong>
              <span>{pay_days}</span>
            </p>
          </div>
          <div className="payment-card__comments">
            <span className="payment-card__observation">Observaciones</span>
            <span>{comments}</span>
          </div>
        </div>
        <div className="payment-card__bottom">
          <table className="payment-card__table">
            <thead>
              <tr>
                <th style={{ fontSize: "9px", height: "40px" }}>Fecha</th>
                <th style={{ fontSize: "9px", height: "40px" }}>Recibo</th>
                <th style={{ fontSize: "9px", height: "40px" }}>Abonos</th>
                <th style={{ fontSize: "9px", height: "40px" }}>Saldo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentCardToPrint;
