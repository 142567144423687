import { makeStyles } from "@material-ui/core/styles";

const LoginPageStyles = makeStyles((theme) => ({
  imageLoginContainer: {
    background: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      borderBottomRightRadius: 80,
      borderBottomLeftRadius: 80,
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
    },
  },

  navickLogo: {
    fontSize: 18,
    color: theme.palette.primary.contrastText,
    marginLeft: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: 22,
    },
  },
  iniciarSesionText: {
    color: theme.palette.primary.gray_50,
    marginTop: 30,
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
      marginTop: 50,
      marginBottom: 20,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom:50
    
    },
  },
  inputUser: {
    borderColor: theme.palette.primary.light,
    [theme.breakpoints.down("md")]: {
      width: 320,
    },
    [theme.breakpoints.up("md")]: {
      width: 380,
    },
  },
  inputPassword: {
    borderColor: theme.palette.primary.light,
    [theme.breakpoints.down("md")]: {
      width: 320,
      marginTop: 20,
    },
    [theme.breakpoints.up("md")]: {
      width: 380,
      marginTop:25
    },
  },
  loginFieldsContainer: {
    color: theme.palette.background.paper,
    marginTop: 20,
    height: 400,
    width: 360,
    borderRadius: 10,
  },
  buttonLogin: {
    height: 55,
    width: 280,
    borderRadius: 10,
    marginTop: 50,
  },
  LoginSlogan: {
    fontSize: 36,
    color: theme.palette.primary.contrastText,
    marginRight: 120,
    [theme.breakpoints.up("lg")]: {
      fontSize: 28,
      lineHeight: "40px",
      marginRight: 100,
      marginLeft: 50,
      marginTop: 20,
    },
    "@media (min-height:930px)": {
      marginTop: 100,
      marginBottom:120
    },
  },
  navickTitle: {
    color: theme.palette.primary.darkGray,
    fontSize: 50,
  },
  avatarImage: {
    [theme.breakpoints.down("xs")]: {
      height: 240,
      width: 295,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
      width: 330,
    },
    [theme.breakpoints.only("lg")]: {
      height: 481,
      width: 595,
      marginTop: 30,
    },
    "@media (max-height:700px)": {
      height: 350,
      width: 410,
    },

    "@media (max-height:600px)": {
      height: 280,
      width: 350,
    },
  },
  errorMessage:{
    color:theme.palette.error.main,
    marginTop:10
  }
}));

export default LoginPageStyles;
