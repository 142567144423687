import { Box, Typography } from "@material-ui/core";
import React from "react";
import AnimationContent from "../AnimationContent";
import ContentAnimationCardStyles from "./ContentAnimationCard.styles";

const ContentAnimationCard = (props) => {
  const {
    cardTitle,
    cardDescription,
    cardBottomDescription,
    animationStyles,
    animationFile,
    widthAnimation,
    heigthAnimation,
  } = props;
  const classes = ContentAnimationCardStyles();
  return (
    <Box className={animationStyles && animationStyles}>
      <Typography variant="h5" align="center" className={classes.cardTitle}>
        {cardTitle}
      </Typography>
      <Box className={classes.contentContainer}>
        <Typography
          variant="subtitle2"
          className={classes.cardDescription}
          align="center"
        >
          {cardDescription}
        </Typography>
        <div className={classes.dashboardAnimation}>
          <AnimationContent
            animationFile={animationFile}
            width={widthAnimation && widthAnimation}
            heigth={heigthAnimation && heigthAnimation}
          />
        </div>
        <Typography
          variant="subtitle2"
          className={classes.cardDescription}
          align="center"
        >
          {cardBottomDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContentAnimationCard;
