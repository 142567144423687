import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserInformationBodyModalStyles from "./UserInformationBodyModal.styles";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/user";
import { selectAvailableRoutes } from "../../../store/slices/routes";
import AnimationContent from "../../sharedComponents/AnimationContent";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
const UserinformationBodyModal = (props) => {
  const {
    handleClose,
    type,
    disabled,
    saveInformationHandler,
    loading,
    success,
  } = props;

  const userInformation = useSelector(selectCurrentUser);
  const availableRoutes = useSelector(selectAvailableRoutes);
  const classes = UserInformationBodyModalStyles();
  const [currentUserInformation, setCurrentUserInformation] = useState({});
  useEffect(() => {
    if (type && (type === "view" || type === "edit")) {
      setCurrentUserInformation({
        idUser: userInformation.id_user,
        username: userInformation.username,
        name: userInformation.name,
        idUserType: userInformation.id_user_type,
        password: userInformation.password,
        idRoute: parseInt(userInformation.id_route),
        status: userInformation.status,
      });
    } else {
      setCurrentUserInformation({
        idUser: "",
        username: "",
        name: "",
        idUserType: "",
        password: "",
        idRoute: "",
        status: "",
      });
    }
  }, [
    type,
    userInformation.id_route,
    userInformation.id_user,
    userInformation.id_user_type,
    userInformation.name,
    userInformation.password,
    userInformation.status,
    userInformation.username,
  ]);
  const { username, name, idUserType, password, idRoute, status } =
    currentUserInformation;
  const disableSaveButton =
    !username || !name || !idUserType || !password || !idRoute || !status
      ? true
      : false;

  const TIPO_USUARIO_VARIANTS = [
    { id: 1, desc: "SUPER" },
    { id: 2, desc: "ADMINISTRADOR" },
    { id: 3, desc: "EDITOR" },
    { id: 4, desc: "VISOR" },
    { id: 5, desc: "APP RUTA" },
    { id: 6, desc: "APP SUPERVISOR" },
  ];

  const STATUS_VARIANTS = [
    { id: 0, desc: "Inactivo" },
    { id: 1, desc: "Activo" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUserInformation((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const isCentered = loading || success;
  const [changePasswordCheck, setChangePasswordCheck] = useState(false);
  return (
    <Box
      className={classes.mainContainer}
      display={isCentered && "flex"}
      alignItems={isCentered && "center"}
      justifyContent={isCentered && "center"}
    >
      {loading ? (
        <Box>
          {success ? (
            <React.Fragment>
              <AnimationContent
                animationFile={success ? successAnimation : loadingAnimation}
              />
              <Typography align="center" variant="subtitle1">
                {type === "new"
                  ? "Usuario Creado con éxito"
                  : "Usuario actualizado con éxito"}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AnimationContent
                animationFile={success ? successAnimation : loadingAnimation}
              />
              <Typography align="center" variant="subtitle1">
                {type === "new" ? "Creando usuario" : "Actualizando usuario"}
              </Typography>
              <Typography align="center" variant="subtitle2">
                Por favor, no cierres la pantalla
              </Typography>
            </React.Fragment>
          )}
        </Box>
      ) : (
        <React.Fragment>
          <div className={classes.closeIconContainerMain} onClick={handleClose}>
            <IconButton>
              <MdOutlineClose />
            </IconButton>
          </div>
          <Box className={classes.contentContainer}>
            <Typography
              variant="h5"
              align="center"
              className={classes.titleBodyModal}
            >
              Información del usuario
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Nombre
                </Typography>
                <TextField
                  disabled={disabled}
                  variant="outlined"
                  name="name"
                  onChange={(e) => handleChange(e)}
                  value={name && name}
                  inputProps={{ className: classes.input }}
                  className={classes.inputContainer}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Usuario
                </Typography>
                <TextField
                  disabled={disabled}
                  variant="outlined"
                  name="username"
                  onChange={(e) => handleChange(e)}
                  value={username && username}
                  inputProps={{ className: classes.input }}
                  className={classes.inputContainer}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Tipo de usuario
                </Typography>
                <Select
                  disabled={disabled}
                  variant="outlined"
                  name="idUserType"
                  value={idUserType ? parseInt(idUserType) : 0}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 200 }}
                >
                  {TIPO_USUARIO_VARIANTS?.map((tipo) => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      {tipo.desc}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    className={classes.changePasswordCheck}
                    checked={changePasswordCheck}
                    onChange={() =>
                      setChangePasswordCheck(!changePasswordCheck)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography variant="body1">Cambiar Password</Typography>
                </Box>
                {changePasswordCheck && (
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      className={classes.inputEnabled}
                    >
                      Contraseña
                    </Typography>
                    <TextField
                      disabled={disabled}
                      variant="outlined"
                      name="password"
                      onChange={(e) => handleChange(e)}
                      value={password && password}
                      inputProps={{ className: classes.input }}
                      className={classes.inputContainer}
                    />
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={7} className={classes.inputContainer}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  Ruta designada
                </Typography>
                <Select
                  disabled={disabled}
                  variant="outlined"
                  name="idRoute"
                  value={parseInt(idRoute)}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 200 }}
                >
                  {availableRoutes?.map((route) => (
                    <MenuItem
                      key={parseInt(route.id_route)}
                      value={parseInt(route.id_route)}
                    >
                      {route.description}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  className={classes.inputEnabled}
                >
                  estado
                </Typography>
                <Select
                  disabled={disabled}
                  variant="outlined"
                  name="status"
                  value={String(status)}
                  onChange={(e) => handleChange(e)}
                  style={{ width: 150 }}
                >
                  {STATUS_VARIANTS?.map((status) => {
                    return (
                      <MenuItem key={status.id} value={status.id}>
                        {status.desc}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {(type === "edit" || type === "new") && (
                <React.Fragment>
                  <Button
                    disabled={type === "new" && disableSaveButton}
                    className={classes.saveButton}
                    type="contained"
                    color="primary"
                    onClick={() =>
                      saveInformationHandler(currentUserInformation, changePasswordCheck)
                    }
                  >
                    Guardar
                  </Button>
                </React.Fragment>
              )}
            </Grid>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default UserinformationBodyModal;
