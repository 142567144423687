import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import ClientCardStyles from "./ClientCard.styles";
import { AiFillDelete } from "react-icons/ai";

// import { formatNumber } from "../../../utils/formatUtils";
const ClientCard = (props) => {
  const { client, handleRemove, key } = props;
  const { name } = client;
  const classes = ClientCardStyles();

  return (
    <Box
      className={classes.mainContainer}
      display="flex"
      flexDirection="row"
      key={key}
    >
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.cardTitle}>
          {name}
        </Typography>
      </Box>
      <Box className={classes.iconContainer}>
        <IconButton
          className={classes.removeIcon}
          onClick={() => handleRemove(client.id_client)}
        >
          <AiFillDelete />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ClientCard;
