import { Box, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";

const RectangleInfo = (props) => {
  const { title, subtitle, classes: c } = props;

  return (
    <Box className={c.rectangleInfo}>
      <Typography className={c.rectangleTitle}>{title}</Typography>
      <Typography className={c.rectangleSubtitle}>{subtitle}</Typography>
    </Box>
  );
};

const getDayofTheWeek = () => {
  const daysList = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];

  const d = new Date();
  const day = d.getDay() - 1;

  return daysList[day];
};

const CircleCard = (props) => {
  const { value, text, classes: c } = props;

  return (
    <Box className={c.circleCard}>
      <Box className={c.circleItem}>
        <Typography className={c.circleItemText}>{value}</Typography>
      </Box>
      <Typography className={c.circleBottomText}>{text}</Typography>
    </Box>
  );
};

const WeeklyPayments = (props) => {
  const { classes: c, data, zona, isForeignZone } = props;
  const [totalBill, setTotalBill] = useState(0);
  const [proBalance, setProBalance] = useState(0);
  const [conBalance, setConBalance] = useState(0);
  const [totalBillText, setTotalBillText] = useState(0);

  const {
    payed_invoices: pagadas,
    // end_day_week,
    // id_route,
    new_invoices: nuevas,
    not_payed: cobro,
    // start_day_week,
    // total_agent,
    total_invoices: total,
    // total_payed_invoices,
    total_remaining_payment: totalPago,
    total_payed_invoices: totalCurrentPaymentAmount,
  } = data;

  const d = [
    { text: "Total", value: total },
    { text: "Nuevas", value: nuevas },
    { text: "Pagadas", value: pagadas },
    { text: "A cobro", value: cobro },
    {
      text: "Cantidad cobrada",
      value: "$" + parseFloat(totalCurrentPaymentAmount),
    },
    {
      text: "Porcentaje alcanzado",
      value: (() => {
        if (parseFloat(total) === 0) return "0%";
        return `${((parseFloat(pagadas) / parseFloat(total)) * 100).toFixed(
          0
        )}%`;
      })(),
    },
  ];

  const currencyFormat = (v) => {
    const mxnCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return mxnCurrency.format(v);
  };

  useEffect(() => {
    const FIX_TOTAL_BALANCE = isForeignZone
      ? parseFloat(totalCurrentPaymentAmount) * 0.15
      : parseFloat(totalCurrentPaymentAmount) * 0.1;
    setTotalBill(FIX_TOTAL_BALANCE);
  }, [totalCurrentPaymentAmount, isForeignZone]);

  useEffect(() => {
    const BALANCE =
      parseFloat(totalBill) + parseFloat(proBalance) - parseFloat(conBalance);
    setTotalBillText(BALANCE);
  }, [proBalance, conBalance, totalBill]);

  const handleProBalance = (e) => {
    const val = e.target.value;
    if (isNaN(val) || val === "") {
      setProBalance(0);
      return;
    }
    setProBalance(val);
  };

  const handleConBalance = (e) => {
    const val = e.target.value;
    if (isNaN(val) || val === "") {
      setConBalance(0);
      return;
    }
    setConBalance(val);
  };

  return (
    <Box sx={{ paddingBottom: "3rem" }}>
      <Typography variant="h1" className={c.subtitle2}>
        Cobranza Semanal
      </Typography>
      <Box>
        <Box className={c.rectangleInfoContainer}>
          <RectangleInfo
            classes={c}
            title="Dia de la semana"
            subtitle={getDayofTheWeek()}
          />
          <RectangleInfo classes={c} title="Zona" subtitle={zona} />
        </Box>
        <Box>
          <Typography className={c.sectionTitle}>Tarjetas</Typography>
          <Box className={c.circleCardContainer}>
            {Object.entries(d).map((item, index, array) => {
              const EDITABLE_CIRCLE = parseInt(array.at(-1)[0]) === index;
              return (
                <CircleCard
                  value={item[1].value}
                  text={item[1].text}
                  classes={c}
                  key={index}
                  isEditable={EDITABLE_CIRCLE}
                />
              );
            })}
          </Box>
        </Box>
        <Box className={c.currencyZoneContainer}>
          <Typography className={c.sectionTitle}>
            Dinero Total en Zona
          </Typography>
          <Box className={c.currencyZoneBox}>
            <Typography className={c.currencyZoneTotal}>
              {currencyFormat(totalPago)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography className={c.sectionTitle}>Cobro de la zona</Typography>
          <Box className={c.inputsContainer}>
            <TextField
              id="favor"
              label="$ Saldo a favor"
              variant="outlined"
              placeholder="0"
              defaultValue={proBalance ?? 0}
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              onKeyUp={handleProBalance}
            />
            <TextField
              id="cont"
              label="$ Saldo en contra"
              variant="outlined"
              placeholder="0"
              defaultValue={conBalance ?? 0}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={handleConBalance}
              type="number"
            />
          </Box>
          <Typography className={c.sectionTitleBold}>Total</Typography>
          <Typography className={c.totalBold} variant="h6">
            {currencyFormat(totalBillText)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WeeklyPayments;
