import { makeStyles } from "@material-ui/core/styles";

const DrawerMenuStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 15,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    width: 240,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) - 3,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  NavickDrawerItem: {
    fontSize: 36,
    color: theme.palette.primary.contrastText,
    marginBottom: 10,
  },
  listItem: {
    height: 55,
  },
  listItemText: {
    ...theme.typography.itemsTypography,
    color: theme.palette.primary.contrastText,
  },
  listSubItemText: {
    ...theme.typography.itemsTypography,
    fontSize: 16,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    fontSize: 22,
    color: theme.palette.primary.contrastText,
  },
  subIcon: {
    fontSize: 19,
    color: theme.palette.primary.contrastText,
    marginLeft: 20,
  },
}));

export default DrawerMenuStyles;
