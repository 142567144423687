import axios from "axios";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getInvoices = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `https://${REACT_APP_NAVICK_API_URL}/invoices`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const createNewInvoice = (payload, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/invoices`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const cancelInvoice = (payload, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/invoices/cancel`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
