import { makeStyles } from "@material-ui/core/styles";

const CreateInvoicesStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: -40,
    marginBottom:100
  },
  clientNameInput: {
    width: 300,
    marginTop: 25,
  },
  productsDetail:{
    marginTop:50,
    color:theme.palette.primary.gray_30,
    marginBottom:20
  },
  chooseProductsTitle:{
    marginTop:20,
  },
  imageDisabled:{
    height:120,
    width:120,    
    fill:theme.palette.primary.gray_30,
  },
  totalInvoiceAmountText:{
    marginTop:40
  },
  totalInvoiceAmountTNumber:{
    marginTop:10
    ,marginBottom:20
  },
  paymentTypeText:{
    marginTop:10,
    marginBottom:15
  }
}));

export default CreateInvoicesStyles;
