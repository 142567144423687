import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientsByRoute } from "../../../api/ApiRoutes";
import { GENERAL_CONSTANTS } from "../../../constants/credentials";
import { selectAccessToken } from "../../../store/slices/clients";
import Map from "../../Map";
import routeMapStyles from "./RouteMap.styles";

export const RouteMap = (props) => {
  const { IdRoute } = props;
  const classes = routeMapStyles();
  const [arrayClients, setArrayClients] = useState([]);
  const accesToken = useSelector(selectAccessToken);
  useEffect(() => {
    const getClients = async () => {
      try {
        const result = await getClientsByRoute(IdRoute, accesToken);
        if (result.data.statusCode === 200) {
          setArrayClients(result.data.result);
        } else {
          console.log("Error de servidor, algo salió mal");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getClients();
  }, [IdRoute, accesToken]);

  return (
    <Box className={classes.mainContainer}>
      <Grid container>
        <Box className={classes.mapContainer}>
          <Map
            googleMapURL={GENERAL_CONSTANTS.URL_API_MAPS}
            containerElement={<Box className={classes.googleMapsContainer} />}
            mapElement={<Box className={classes.googleMapsElement} />}
            loadingElement={<p>Cargando...</p>}
            arrayMarkers={arrayClients}
            lat={0}
            long={0}
          />
        </Box>
      </Grid>
    </Box>
  );
};
