export const ROUTES_CONSTANTS = {
    SPLASH:"/",
    LOGIN:'/iniciarSesion',
    ADMIN:'/administrador/',
    CLIENTS:'/administrador/clientes',
    CLIENTS_DETAILS:'/administrador/clientes/:idClient',
    ROUTES:'/administrador/rutas',
    INVOICE:'/administrador/facturas',
    INVENTORY:'/administrador/inventario',
    USERS:'/administrador/usuarios',
    PAYMENTS:'/administrador/cobranza',
    REMAINING_PAYMENTS:'/administrador/fichas',
    AUDITS:"/administrador/auditoria",
    PAYMENTS_ZONE: '/administrador/zona_cobranza'
}