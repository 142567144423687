import { makeStyles } from "@material-ui/core/styles";

const PaymentsStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 50,
    maxWidth:"90%",
    minWidth:"80%",
    marginBottom:100
  },
  paymentsTitle: {
    marginTop: 40,
    color: theme.palette.primary.dark,
    marginBottom:20
  },
  table: {
    minWidth: 800,
  },
  tableContainer:{
      marginTop:50,
      marginBottom:50
  },
  datePicker:{
    marginBottom:30
  }
  
}));

export default PaymentsStyles;
