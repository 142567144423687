import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS,COLOR_CONSTANTS } from "../../../constants/styleConstants";

const deleteRoutesStyles = makeStyles((theme) => ({
  
    deleteRouteButton:{
        backgroundColor: theme.palette.primary.dark,
        color:theme.palette.primary.white,
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD,
        fontSize: 16,
        float: "left",
        marginRight: 142,
        height: 55,
        width: 155,
        borderRadius: 10,
        marginTop:25,
      },
      cancelDeleteRouteButton:{
        backgroundColor: theme.palette.primary.white,
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD,
        fontSize: 16,
        float: "left",
        marginRight: 142,
        height: 55,
        width: 155,
        borderRadius: 10,
        marginTop:25,
        color:COLOR_CONSTANTS.DARK_GREEN
      },
      mainContainer:{
        backgroundColor:theme.palette.primary.white,
        width:567,
        height:316,
        borderRadius:10,
        padding:70
      },
      DescriptionInputStyle:{
        width:"100%",
        marginTop:30
      },
      deleteRouteTitle:{
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
        color:COLOR_CONSTANTS.text_dark,
        textAlign:"center"
      },
      deleteRouteSubTitle:{
        color:COLOR_CONSTANTS.gray_50,
        textAlign:"center"

      },
      subtitleBox:{
        marginTop:50
      },
      animationTitle:{
        marginBottom: 10,
        color: theme.palette.primary.dark,
      }
 
}));

export default deleteRoutesStyles;
