import { makeStyles } from "@material-ui/core/styles";

const ContentAnimationCardStyles = makeStyles((theme) => ({
  cardDescription: {
    marginTop: 20,
    color: theme.palette.primary.dark,
    lineHeight: "22px",
    marginLeft: 80,
    marginRight: 80,
    marginBottom: 10,
  },
}));

export default ContentAnimationCardStyles;
