import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../constants/styleConstants";

export const globalStyles = makeStyles((theme) => ({
    mainContainer: {
        marginLeft: 80,
        width: "100%",
    },
    h1Title:{
        color: theme.palette.primary.main,
        marginTop: 30,
    },
    h1SubTitle:{
        marginTop: 30,
        color:theme.palette.primary.gray_50,
        fontFamily:FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD
    },
   
}));


