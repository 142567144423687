import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  formatDateToUTC,
} from "../../../utils/formatUtils";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row, dailyPayments } = props;
  const [open, setOpen] = React.useState(false);
  dailyPayments.sort((a, b) => formatDateToUTC(a.created_at) < formatDateToUTC(b.created_at))
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.username}</TableCell>
        <TableCell align="center">{row.payments}</TableCell>
        <TableCell align="center">{row.amount}</TableCell>
        <TableCell align="center">{formatDateToUTC(row.date)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle de pagos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID Abono</TableCell>
                    <TableCell align="center">ID factura</TableCell>
                    <TableCell align="center">Cantidad</TableCell>
                    <TableCell align="center">Fecha</TableCell>
                    <TableCell align="center">Cliente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyPayments.length > 0 ? (
                    dailyPayments.map((pay) => (
                      <TableRow key={pay.id_payment}>
                        <TableCell align="center">{pay.id_payment}</TableCell>
                        <TableCell align="center">{pay.id_invoice}</TableCell>
                        <TableCell align="center">
                          {pay.total_payment}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateToUTC(pay.created_at)}
                        </TableCell>
                        <TableCell align="center">{pay.client_name}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        Sin datos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  const { rows, dailyPayments } = props;
  const filterPayments = (idUser) => {
    const data =
      dailyPayments && dailyPayments.length > 0
        ? dailyPayments.filter((pay) => pay.id_user === parseInt(idUser))
        : [];
    return data;
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Usuario</TableCell>
            <TableCell align="center">Abonos</TableCell>
            <TableCell align="center">Cantidad</TableCell>
            <TableCell align="center">Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map(
              (row) =>
                row.amount > 0 && (
                  <Row
                    key={row.userId}
                    row={row}
                    dailyPayments={filterPayments(row.userId)}
                  />
                )
            )
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                Sin datos
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
