import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../../constants/styleConstants";
const DialogBodyModalStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 316,
    width: 567,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
  },
  closeIconContainerMain: {
    float: "right",
    marginTop: -15,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
  },
  deleteButton: {
    width: 140,
    height: 45,
    borderRadius: 6,
  },
  cancelButton: {
    width: 140,
    height: 45,
    borderRadius: 6,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    color: theme.palette.primary.dark,
  },
  cardTitle: {
    marginTop: 40,
    marginBottom: 40,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 22,
  },
  lottieAnimation: {},
  cardUserName: {
    fontSize: 22,
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginBottom: 80,
  },
  subtitleText: {
    marginTop: 20,
    fontSize: 22,
  },
  userDeletedTitle: {
    marginBottom: 10,
    color: theme.palette.primary.dark,
  },
  userDeletedSubtitle: {
    marginRight: 10,
    marginLeft: 10,
    color: theme.palette.primary.dark,
  },
  closeIconContainer: {
    float: "right",
    marginTop: -50,
  },
  animationTitle: {
    marginTop: 25,
    color: theme.palette.primary.dark,
  },
}));

export default DialogBodyModalStyles;
