import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsByDay } from "../../api/ApiPayments";
import { getUsers } from "../../api/apiUsers";
import DrawerMenu from "../../components/DrawerMenu/Index";
import {
  resetDailyPayments,
  selectDailyPayments,
  selectFilteredDailyPayments,
  setDailyPayments,
  setFilterDailyPayments,
} from "../../store/slices/payments";
import {
  selectAvailableUsers,
  setAvailableUsers,
  setIsAuth,
} from "../../store/slices/user";
import PaymentsStyles from "./Payments.styles";
import CollapedTable from "./CollapsedTable";
import DatePicker from "../../components/sharedComponents/DatePicker";
import { selectAccessToken, setAccessToken } from "../../store/slices/clients";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Payments = () => {
  const classes = PaymentsStyles();
  // const [, setTotalFilteredInvoices] = useState([]);
  const dispatch = useDispatch();
  const availableUsers = useSelector(selectAvailableUsers);
  const rows = useSelector(selectFilteredDailyPayments);
  const dailyPayments = useSelector(selectDailyPayments);
  const accessToken = useSelector(selectAccessToken);
  const history = useHistory();
  const today = new Date();
  const initialDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const handleChangeDate = (date) => {
    var newDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setSelectedDate(newDate);
  };

  useEffect(() => {
    getUsers(accessToken)
      .then((res) => {
        dispatch(setAvailableUsers(res.data.result));
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  }, [accessToken, dispatch, history]);

  useEffect(() => {
    getPaymentsByDay(selectedDate, accessToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result.length > 0) {
            dispatch(setDailyPayments(res.data.result));
          } else {
            dispatch(resetDailyPayments());
          }
        }
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  }, [accessToken, dispatch, history, selectedDate]);

  useEffect(() => {
    async function getInvoicesInformation(accessToken) {
      const totalInvoicesFiltered = [];
      await availableUsers.forEach(async (user) => {
        let filteredInvoices = await dailyPayments.filter(
          (payment) => payment.id_user === parseInt(user.id_user)
        );
        let amount = filteredInvoices.reduce(
          (sum, item) => sum + parseInt(item.total_payment),
          0
        );
        const date =
          dailyPayments.length > 0 ? dailyPayments[0]["created_at"] : "";

        let data = {
          userId: user.id_user,
          username: user.username,
          payments: filteredInvoices.length,
          amount: amount,
          date: date,
        };
        totalInvoicesFiltered.push(data);
      });
      // setTotalFilteredInvoices(totalInvoicesFiltered);
      dispatch(setFilterDailyPayments(totalInvoicesFiltered));
    }
    if (dailyPayments.length > 0) {
      getInvoicesInformation(accessToken);
    }
  }, [accessToken, availableUsers, dailyPayments, dispatch]);

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h2" className={classes.paymentsTitle}>
          Abonos
        </Typography>
        <Typography variant="subtitle2" className={classes.paymentsTitle}>
          Elige la fecha que quieres visualizar
        </Typography>
        <DatePicker
          selectedDate={selectedDate}
          handleChangeDate={handleChangeDate}
          dateStyles={classes.datePicker}
        />
        <CollapedTable
          rows={rows}
          dailyPayments={dailyPayments}
          selectedDate={selectedDate}
        />
      </Box>
    </DrawerMenu>
  );
};

export default Payments;
