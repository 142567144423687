import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    maxWidth: 900,
  },
});

export default function DataTableClientsInfo(props) {
  const classes = useStyles();
  const { rows, addClientToListHandler } = props;
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Id factura</TableCell>
            <TableCell align="center">Cliente</TableCell>
            <TableCell align="center">Saldo</TableCell>
            <TableCell align="center">Ruta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id_invoice}
              onClick={() => addClientToListHandler(row)}
            >
              <TableCell align="center">{parseInt(row.id_invoice)}</TableCell>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.remaining_payment}</TableCell>
              <TableCell align="center">{row.route_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
