
import { createTheme } from "@material-ui/core";
import { themeBreakpoints } from "./themeBreakpoints";
import { themeOverrides } from "./themeOverrides";
import { themePalette } from "./themePalette";
import { themeTypography } from "./themeTypography";

let theme = createTheme();

const Theme = createTheme(theme, {
  breakpoints: themeBreakpoints,
  overrides: themeOverrides,
  palette: themePalette,
  typography: themeTypography,
  paddings: {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30,
    xxl: 40,
    xxxl: 65,
  },
});
export default Theme;
