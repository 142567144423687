import { COLOR_CONSTANTS } from "../../constants/styleConstants";

export const themePalette = {
  primary: {
    main: COLOR_CONSTANTS.MAIN_GREEN,
    light: COLOR_CONSTANTS.LIGHT_GREEN,
    dark: COLOR_CONSTANTS.DARK_GREEN,
    text_dark: COLOR_CONSTANTS.TEXT_DARK,
    text_dark_disabled: COLOR_CONSTANTS.TEXT_DARK_DISABLED,
    text_dark_md: COLOR_CONSTANTS.TEXT_DARK_MEDIUM,
    text_gray: COLOR_CONSTANTS.TEXT_GRAY,
    darkGray: COLOR_CONSTANTS.MAIN_GRAY,
    gray_50: COLOR_CONSTANTS.GRAY_50,
    gray_40: COLOR_CONSTANTS.GRAY_40,
    gray_30: COLOR_CONSTANTS.GRAY_30,
    extra_light: COLOR_CONSTANTS.EXTRA_LIGHT,
    apple_green: COLOR_CONSTANTS.APPLE_GREEN,
    green_60: COLOR_CONSTANTS.GREEN_60,
    green_30: COLOR_CONSTANTS.GREEN_30,
    green_10: COLOR_CONSTANTS.GREEN_10,
    white: COLOR_CONSTANTS.WHITE,
  },
  secondary: {
    main: COLOR_CONSTANTS.SECONDARY_GREEN,
    inactive: COLOR_CONSTANTS.INACTIVE_ERROR,
  },
};
