import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../../constants/styleConstants";

const PayInvoiceStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 400,
    height: 720,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 40,
    color: theme.palette.primary.dark,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_MEDIUM,
  },
  amountTitle: {
    marginBottom: 20,
  },
  amount: {
    marginBottom: 40,
  },
  pay: {
    marginTop: 10,
  },
  restAmount: {
    marginTop: 20,
  },
  paymentButton: {
    width: 150,
    height: 40,
    borderRadius: 50,
    marginTop: 20,
  },
  closeIconContainerMain: {
    marginLeft: 340,
    marginTop: 5,
  },
  commentsField: {
    marginTop: 10,
  },
  commentsMultiline: {
    maxHeight: 70,
    minHeight: 80,
    maxWidth: 210,
    minWidth: 210,
  },
}));

export default PayInvoiceStyles;
