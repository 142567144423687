import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuth } from "../../store/slices/user";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { selectAccessToken } from "../../store/slices/clients";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = useSelector(selectIsAuth);
  const accesToken = useSelector(selectAccessToken);
  // const isAuth = true

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isAuth && accesToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES_CONSTANTS.LOGIN} />
        )
      }
    />
  );
};
export default PrivateRoute;
