import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import { ROUTES_CONSTANTS } from "./constants/routesConstants";
import Administrator from "./pages/Administrator";
import Clients from "./pages/Clients";
import Invoices from "./pages/Invoices";
import LoginPage from "./pages/LoginPage";
import RoutesPage from "./pages/Routes";
import SplashScreen from "./pages/SplashScreen";
import UsersPage from "./pages/Users";
import Payments from "./pages/Payments";
import RemainingPayments from "./pages/RemainingPayments";
import Audits from "./pages/Audits";
import Dashboards from './pages/Dashboards'
import ClientDetailsInformation from "./pages/ClientsDetailsInformation";

const AppRouter = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          restricted={false}
          component={SplashScreen}
          path={ROUTES_CONSTANTS.SPLASH}
          exact
        />
        <PublicRoute
          restricted={false}
          component={LoginPage}
          path={ROUTES_CONSTANTS.LOGIN}
          exact
        />

        <PrivateRoute
          component={Administrator}
          path={ROUTES_CONSTANTS.ADMIN}
          exact
        />

        <PrivateRoute
          component={ClientDetailsInformation}
          path={ROUTES_CONSTANTS.CLIENTS_DETAILS}
          exact
        />

        <PrivateRoute
          component={Clients}
          path={ROUTES_CONSTANTS.CLIENTS}
          exact
        />
        <PrivateRoute
          component={Invoices}
          path={ROUTES_CONSTANTS.INVOICE}
          exact
        />
        <PrivateRoute
          component={RoutesPage}
          path={ROUTES_CONSTANTS.ROUTES}
          exact
        />
        <PrivateRoute
          component={UsersPage}
          path={ROUTES_CONSTANTS.USERS}
          exact
        />
        <PrivateRoute
          component={Payments}
          path={ROUTES_CONSTANTS.PAYMENTS}
          exact
        />
        <PrivateRoute
          component={RemainingPayments}
          path={ROUTES_CONSTANTS.REMAINING_PAYMENTS}
          exact
        />
        <PrivateRoute
          component={Audits}
          path={ROUTES_CONSTANTS.AUDITS}
          exact
        />
        <PrivateRoute
          component={Dashboards}
          path={ROUTES_CONSTANTS.DASHBOARDS}
          exact
        />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
