import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAvailableRoutes } from "../store/slices/routes";
import { selectIdRoute } from "../store/slices/user";

const useGetRouteName = () => {
  const availableRoutes = useSelector(selectAvailableRoutes);
  const idRoute = useSelector(selectIdRoute);
  const [routeName, setRouteName] = useState("");

  useEffect(() => {

    const itemIndex = availableRoutes.findIndex(
      (routes) => parseInt(routes.id_route) === idRoute
    );
    if (itemIndex > 0) {
       setRouteName(availableRoutes[itemIndex].description)
    } else {
     setRouteName("Sin ruta")
    }

  }, [availableRoutes, idRoute]);
  return routeName;
};

export default useGetRouteName;
