import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import CancelInvoiceByIdModalStyles from "./CancelInvoiceByIdModal.styles";
import { MdClose } from "react-icons/md";
import CenteredContent from "../../sharedComponents/CenteredContent";
import { useSelector } from "react-redux";
import {
  selectUserId,
  selectUserName,
} from "../../../store/slices/user";
import {
  CANCEL_INVOICE_TEXT_TICKET,
} from "../../../constants/constantMessages";
import AnimationContent from "../../sharedComponents/AnimationContent";
import LoadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
import { cancelInvoice } from "../../../api/ApiInvoices";
import { selectAccessToken } from "../../../store/slices/clients";
import useGetFolioNumber from "../../../hooks/useGetFolioNumber";
import useGetRouteName from "../../../hooks/useGetRouteName";
const CancelInvoiceByIdModal = (props) => {
  const { invoiceinfo, handleClickCloseModal } = props;
  const idUser = useSelector(selectUserId);
  const [localization, setLocalization] = useState("");
  const [payment, setPayment] = useState(0);
  const [comments, setComments] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [success, setSuccess] = useState(false);
  const username = useSelector(selectUserName);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const idPaymentFormat = useGetFolioNumber();
  const routeName = useGetRouteName();

  useEffect(() => {
    if (navigator.geolocation) {
      //check if geolocation is available
      navigator.geolocation.getCurrentPosition(function (position) {
        let location = position.coords;
        let { latitude, longitude } = location;
        setLocalization(`${latitude}, ${longitude}`);
      });
    } else {
      setLocalization("20.000948124862976, -102.27041548703968");
    }
  }, []);

  const handleChangeAmountValue = (value) => {
    const isNum = /^[0-9]+$/;

    if (!value || value < 0 || !value.match(isNum)) {
      setAmountError(true);
    } else {
      amountError && setAmountError(false);
    }
    setPayment(value);
  };
  const handleSubmitCancelInvoice = () => {
    setLoading(true);
    // cancelInvoice

    const tiket_cancel_info = CANCEL_INVOICE_TEXT_TICKET(
      invoiceinfo.name_client,
      invoiceinfo.id_invoice,
      routeName,
      idPaymentFormat,
      invoiceinfo.remaining_payment,
      payment,
      username
    );

    const payload = {
      idPayment: idPaymentFormat,
      idInvoice: invoiceinfo.id_invoice,
      idUser: parseInt(idUser),
      amount: !payment ? 0 : parseInt(payment),
      locationGPS: localization,
      comments: comments,
      textTicket: tiket_cancel_info,
      printedTicket: false,
    };

    cancelInvoice(payload, accessToken)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          // window.location.reload();
          handleClickCloseModal();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const classes = CancelInvoiceByIdModalStyles();
  return (
    <Box className={classes.mainContainer}>
      {loading || success ? (
        <CenteredContent direction="column">
          <AnimationContent
            animationFile={success ? successAnimation : LoadingAnimation}
            height={300}
            width={300}
          />
          <Typography variant="h6" className={classes.loadinHeader}>
            {success ? "Factura Cancelada" : "Cancelando Factura"}
          </Typography>
          <Typography variant="subtitle2">No cierre la pantalla</Typography>
        </CenteredContent>
      ) : (
        <div>
          <MdClose
            className={classes.closeIcon}
            onClick={handleClickCloseModal}
          />
          {invoiceinfo && (
            <>
              <Typography
                variant="h5"
                align="center"
                className={classes.cancelInvoiceTitle}
              >
                Cancelación de factura
              </Typography>
              <Typography
                variant="subtitle2"
                align="center"
                className={classes.idInvoiceHeader}
              >
                ID de factura: {invoiceinfo.id_invoice}
              </Typography>
              <CenteredContent>
                <Box className={classes.formContainer}>
                  <Typography
                    variant="subtitle2"
                    className={classes.idInvoiceHeader}
                  >
                    Cantidad por cancelación
                  </Typography>
                  <TextField
                    value={payment}
                    onChange={(e) => handleChangeAmountValue(e.target.value)}
                    variant="outlined"
                    className={classes.amountField}
                    error={amountError}
                    helperText={amountError && "Ingrese una cantidad válida"}
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.idInvoiceHeader}
                  >
                    Comentarios (opcional)
                  </Typography>
                  <TextField
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    variant="outlined"
                    className={classes.amountField}
                    multiline={true}
                    rows={5}
                    maxRows={5}
                  />
                </Box>
              </CenteredContent>
              <CenteredContent className={classes.cancelButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.cancelButton}
                  disabled={amountError}
                  onClick={() => handleSubmitCancelInvoice()}
                >
                  Cancelar factura
                </Button>
              </CenteredContent>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default CancelInvoiceByIdModal;
