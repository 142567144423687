import axios from "axios";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getAvailableRoutes = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `https://${REACT_APP_NAVICK_API_URL}/rutas`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const deleteRouteById = (idRoute, accessToken) => {
  let payload = { idRoute };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "DELETE",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/rutas/${idRoute}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const addRoute = (description, accessToken) => {
  let payload = { description };

  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/rutas/`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const getClientsByRoute = (idRoute, accessToken) => {
  let payload = { idRoute };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/clientes/only/${idRoute}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
