import { Box, Button, Typography } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import {
  changeUserStatus,
  createUser,
  getUsers,
  updateUserInformation,
} from "../../api/apiUsers";
import DrawerMenu from "../../components/DrawerMenu/Index";
import UserinformationBodyModal from "../../components/ModalBodies/UserInformationBodyModal";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import UsersDataTable from "../../components/sharedComponents/UsersDataTable";
import UserStylesPage from "./Users.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  setIsAuth,
  setSelectedUser,
} from "../../store/slices/user";
import DialogBodyModal from "../../components/ModalBodies/DialogBodyModal";
import { MdAddCircleOutline } from "react-icons/md";
import { selectAccessToken, setAccessToken } from "../../store/slices/clients";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UsersPage = () => {
  const classes = UserStylesPage();
  const [availableUsers, setAvailableUsers] = useState([]);
  const currentUser = useSelector(selectCurrentUser);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const history = useHistory();
  useLayoutEffect(() => {
    getUsers(accessToken)
      .then((res) => {
        setAvailableUsers(res.data.result);
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  }, [accessToken, dispatch, history]);

  //Modal states
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openUserInformation, setopenUserInformation] = useState(false);
  const [openUpdateUserInformation, setOpenUpdateUserInformation] =
    useState(false);
  const [openDeleteUser, setopenDeleteUser] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [successDeleted, setSuccessDeleted] = useState(false);
  const [loadingDeleted, setLoadingDeleted] = useState(false);
  const [successCreateUser, setSuccessCreateUser] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);

  const handleOpenCreateUser = (user) => {
    setOpenCreateUser(true);
  };
  const handleCloseCreateUser = () => {
    setOpenCreateUser(false);
  };

  const handleOpenUpdateUserInformation = (user) => {
    setOpenUpdateUserInformation(true);
    dispatch(setSelectedUser(user));
  };
  const handleCloseUpdateUserInformation = () => {
    setOpenUpdateUserInformation(false);
  };
  const handleOpenViewUserInformation = (user) => {
    setopenUserInformation(true);
    dispatch(setSelectedUser(user));
  };
  const handleCloseViewUserInformation = () => {
    setopenUserInformation(false);
  };

  const handleOpenDeleteUser = (user) => {
    setopenDeleteUser(true);
    dispatch(setSelectedUser(user));
  };
  const handleCloseDeleteUser = () => {
    setopenDeleteUser(false);
  };

  const handleCreateNewUser = async (user) => {
    const userInformation = {
      username: user.username,
      name: user.name,
      idUserType: user.idUserType,
      password: user.password,
      status: user.status,
      idRoute: user.idRoute,
    };
    setLoadingCreateUser(true);

    await createUser(userInformation, accessToken)
      .then((res) => {
        getUsers(accessToken).then((res) => {
          setAvailableUsers(res.data.result);
          setTimeout(() => {
            setSuccessCreateUser(true);
            setTimeout(() => {
              handleCloseCreateUser();
              setTimeout(() => {
                setLoadingCreateUser(false);
                setSuccessCreateUser(false);
              }, 1500);
            }, 2000);
          }, 3000);
        });
      })
      .catch((err) => {
        if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };

  const handleChangeUserStatus = async (idUser, newStatus) => {
    setLoadingDeleted(true);
    await changeUserStatus(idUser, newStatus)
      .then((res) => {
        getUsers(accessToken).then((res) => {
          setAvailableUsers(res.data.result);
          setTimeout(() => {
            setSuccessDeleted(true);
            setTimeout(() => {
              handleCloseDeleteUser();
              setTimeout(() => {
                setLoadingDeleted(false);
                setSuccessDeleted(false);
              }, 1500);
            }, 2000);
          }, 3000);
        });
      })
      .catch((err) => {
        if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };
  const handleSaveInformation = async (user, passwordChange) => {
    setLoadingUpdate(true);
    const payload = {
      username: user.username,
      name: user.name,
      idUserType: user.idUserType,
      password: passwordChange ? user.password : "",
      status: user.status,
      idRoute: user.idRoute,
    };
    await updateUserInformation(payload)
      .then((res) => {
        getUsers(accessToken).then((res) => {
          setAvailableUsers(res.data.result);
          setTimeout(() => {
            setSuccessUpdate(true);
            setTimeout(() => {
              handleCloseUpdateUserInformation();
              setTimeout(() => {
                setLoadingUpdate(false);
                setSuccessUpdate(false);
              }, 1500);
            }, 2000);
          }, 3000);
        });
      })
      .catch((err) => {
        if (err.response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };

  const headCells = [
    { id: "username", numeric: false, disablePadding: false, label: "Usuario" },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Nombre",
    },
    {
      id: "id_route",
      numeric: true,
      disablePadding: false,
      label: "Ruta designada",
    },
    { id: "status", numeric: false, disablePadding: false, label: "Estado" },
  ];

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h2" className={classes.usersSectionTitle}>
          Listado de Usuarios
        </Typography>
        <Typography variant="subtitle2">
          {availableUsers.length} usuarios disponibles
        </Typography>

        <Button
          startIcon={<MdAddCircleOutline />}
          variant="contained"
          color="primary"
          className={classes.saveUserButton}
          onClick={handleOpenCreateUser}
        >
          Agregar Usuario
        </Button>

        <UsersDataTable
          rows={availableUsers}
          headCells={headCells}
          openViewUserInformation={handleOpenViewUserInformation}
          openDeleteUser={handleOpenDeleteUser}
          openUpdateUserInformation={handleOpenUpdateUserInformation}
        />
      </Box>

      <ModalComponent
        handleClose={handleCloseViewUserInformation}
        open={openUserInformation}
        body={
          <UserinformationBodyModal
            userInformation={currentUser}
            handleClose={handleCloseViewUserInformation}
            type="view"
            disabled={true}
            animationTitle="Actualizando usuario"
            animationSubtitle="Por favor, no cierre la ventana"
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseUpdateUserInformation}
        open={openUpdateUserInformation}
        body={
          <UserinformationBodyModal
            userInformation={currentUser}
            handleClose={handleCloseUpdateUserInformation}
            saveInformationHandler={handleSaveInformation}
            type="edit"
            disabled={false}
            loading={loadingUpdate}
            success={successUpdate}
            animationTitle="Actualizando usuario"
            animationSubtitle="Por favor, no cierre la ventana"
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseCreateUser}
        open={openCreateUser}
        body={
          <UserinformationBodyModal
            handleClose={handleCloseCreateUser}
            saveInformationHandler={handleCreateNewUser}
            type="new"
            disabled={false}
            loading={loadingCreateUser}
            success={successCreateUser}
            animationTitle="Creando el nuevo usuario"
            animationSubtitle="Por favor, no cierre la ventana"
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseDeleteUser}
        open={openDeleteUser}
        body={
          <DialogBodyModal
            userInformation={currentUser}
            titleDialog="¿Estas seguro de eliminar este usuario?"
            subtitleDialog={currentUser && currentUser.username}
            handleCancel={handleCloseDeleteUser}
            handleDelete={handleChangeUserStatus}
            loading={loadingDeleted}
            success={successDeleted}
          />
        }
      />
    </DrawerMenu>
  );
};

export default UsersPage;
