import { makeStyles } from "@material-ui/core/styles";

const UserInformationBodyModalStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 650,
    width: 450,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
    overflow: "auto",
  },
  titleBodyModal: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginTop: 20,
    marginBottom: 40,
  },
  closeIconContainerMain: {
    float: "right",
    marginTop: -15,
  },
  input: {
    width: 340,
  },
  inputSmall: {
    width: 120,
  },
  inputMedium: {
    width: 170,
  },
  inputContainer: {
    marginBottom: 10,
  },
  contentContainer: {
    paddingLeft: 20,
  },
  inputEnabled: {
    marginBottom: 10,
    marginLeft: 3,
    color: theme.palette.primary.dark,
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    width: 120,
    height: 40,
    borderRadius: 10,
    marginTop: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  changePasswordCheck: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

export default UserInformationBodyModalStyles;
