import { makeStyles } from "@material-ui/core/styles";

const UsersDataTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    minWidth: 900,
    maxWidth: "90%",
    marginBottom: theme.spacing(2),
    marginTop: 80,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dataTableheads: {
    ...theme.typography.itemsTypography,
  },
  dataTableItems: {
    ...theme.typography.itemsTableTypography,
  },
  tableCellItem: {
    border: "none",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover, &:focus, &:active,  &:visited": {
      backgroundColor: theme.palette.primary.apple_green + " !important",
    },
  },
  iconActionsContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionIcons: {
    fontSize: 20,
    color: theme.palette.primary.green_60,
    cursor: "pointer",
  },
  activeChipState: {
    width: 110,
    height: 35,
    color: `${theme.palette.primary.white} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    fontWeight: 700,
    fontSize: 14,
  },
  inactiveChipState: {
    width: 110,
    height: 35,
    color: `${theme.palette.primary.white} !important`,
    backgroundColor: `${theme.palette.secondary.inactive} !important`,
    fontWeight: 700,
    fontSize: 14,
  },
}));

export default UsersDataTableStyles;
