import React from "react";
import AnimationContent from "../AnimationContent";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import succesAnimation from "../../../assets/lottieFiles/successAnimation.json";
import errorAnimation from "../../../assets/lottieFiles/errorAnimation.json";
const ModalLoader = (props) => {
  const { heigthContent, widthContent, isLoading, isSuccessfully, onError } =
    props;

  // const renderLottieFile = () => {
  //   if (Isloading) {
  //     return loadingAnimation;
  //   } else if (isSuccessfully) {
  //     return succesAnimation;
  //   } else if (onError) {
  //     return errorAnimation;
  //   }
  // };
  return (
    <>
      {isLoading && (
        <AnimationContent
          height={heigthContent && heigthContent}
          width={widthContent && widthContent}
          animationFile={loadingAnimation}
        />
      )}
      {isSuccessfully && (
        <AnimationContent
          height={heigthContent && heigthContent}
          width={widthContent && widthContent}
          animationFile={succesAnimation}
        />
      )}
      {onError && (
        <AnimationContent
          height={heigthContent && heigthContent}
          width={widthContent && widthContent}
          animationFile={errorAnimation}
        />
      )}
    </>
  );
};

export default ModalLoader;
