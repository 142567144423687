import {
  Box,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PaymentZonesStyles from './dashboards.styles';
import DrawerMenu from '../../components/DrawerMenu/Index';
import { useSelector } from 'react-redux';
import { selectAvailableRoutes } from '../../store/slices/routes';
import { selectAccessToken } from '../../store/slices/clients';
import { getClientBillWeekly } from '../../api/apiClients';
import PaymentZones from './PaymentZones';
import WeeklyPayments from './WeeklyPayments';

const FOREIGN_ZONES =  ['7','13,', '3', '15'];

const Dashboards = () => {

  const classes = PaymentZonesStyles();
  const availableRoutes = useSelector(selectAvailableRoutes);
  const accessToken = useSelector(selectAccessToken);

  const [bills, setBills] = useState([]);
  const [route, setRoute] = useState('');
  const [isForeign, setIsForeign] = useState(false);
  const [selectedBill, setSelectedBill] = useState({});

  useEffect(() => {
    const httpRequest = async () => {
      const request = await getClientBillWeekly(accessToken).then((res) => {
        return res.data.result;
      });
      setBills(request);
    }

    httpRequest();
  }, [accessToken])

  const handleSelectedZoneRoute = (e, d) => { 
    const optionSelected = bills.filter(item => item.id_route === e)[0];
    const isForeignZone = FOREIGN_ZONES.includes(e)
    setIsForeign(isForeignZone);

    setSelectedBill((prev) => {
      return { ...optionSelected }
    });
    setRoute(d);
  }
 
  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.paymentZoneTitle}>
          {" "}
          Cobranza semanal por zona{" "}
        </Typography>
        <Typography variant='h6' className={classes.subtitle}>
          Elige una zona
        </Typography>
        <PaymentZones
          zones={availableRoutes}
          classes={classes}
          selectEvent={(e, d) => { handleSelectedZoneRoute(e, d) }}
        />
        {
          Object.entries(selectedBill).length > 0
            ? <WeeklyPayments classes={classes} data={selectedBill} zona={route} isForeignZone={isForeign}/>
            : null
        }
      </Box>

    </DrawerMenu>
  )
}

export default Dashboards;