import { COLOR_CONSTANTS } from "../../constants/styleConstants";

export const themeOverrides = {
  MuiOutlinedInput: {
    root: {
      borderRadius: 10,
      "& $notchedOutline": {
        borderColor: COLOR_CONSTANTS.EXTRA_LIGHT,
      },
      "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
        borderColor: COLOR_CONSTANTS.LIGHT_GREEN,
        "@media (hover: none)": {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
      },
      "&$focused $notchedOutline": {
        borderColor: COLOR_CONSTANTS.MAIN_GREEN,
        borderWidth: 1,
      },
    },
  },
  MuiButton: {
    root: {
      "&:disabled": {
        backgroundColor: COLOR_CONSTANTS.GRAY_30,
        color: COLOR_CONSTANTS.GRAY_50,
      },
    },
  },
};
