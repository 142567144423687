import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import PricesCardStyles from "./PricesCard.styles";
import { BsPlus } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductsSelected,
  setNewAmount,
  updateProductSelected,
} from "../../../store/slices/products";
import { BsCheckCircle } from "react-icons/bs";

const PriceCard = (props) => {
  const {
    articleName,
    price,
    quantityProducts,
    handleAddQuantity,
    handleRemoveQuantity,
    handleRemoveProduct,
    productDetail,
  } = props;
  const classes = PricesCardStyles();
  const [editPrice, setEditPrice] = useState(false);
  const carProducts = useSelector(selectProductsSelected);
  // const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();

  async function getAmount() {
    let amount = 0;
    carProducts.forEach((prod) => {
      let newPrice = prod.price * prod.quantity;
      amount = parseInt(amount) + parseInt(newPrice);
    });
    return amount;
  }

  const handleChangePrice = async (newPrice) => {
    const payload = {
      idArticle: articleName,
      price: parseInt(newPrice),
    };
    dispatch(updateProductSelected(payload));
  };

  const handleChangeNewAmount = async () => {
    const amount = await getAmount();
    dispatch(setNewAmount(parseInt(amount)));
    setEditPrice(false);
  };

  const removeQuantity = () => {
    if (quantityProducts > 1) {
      handleRemoveQuantity(productDetail);
    }
  };

  return (
    <Grid container className={classes.CardContainer}>
      <Grid item md={3}>
        <Typography variant="subtitle2" className={classes.productDescription}>
          {articleName}
        </Typography>
        <Typography variant="body2" className={classes.productPrice}>
          ${price}.00
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Box className={classes.iconsContainer}>
          <IconButton className={classes.icon} onClick={removeQuantity}>
            <FiMinus />
          </IconButton>
          <Typography variant="subtitle1" className={classes.amount}>
            {quantityProducts}
          </Typography>
          <IconButton
            className={classes.icon}
            onClick={() => handleAddQuantity(productDetail)}
          >
            <BsPlus />
          </IconButton>
        </Box>
      </Grid>
      <Grid item md={1}>
        {!editPrice ? (
          <Typography variant="body2" className={classes.productPriceBig}>
            ${price}.00
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            value={price}
            inputProps={{ className: classes.inputPrice }}
            style={{ width: 80, marginTop: 10 }}
            onChange={(e) => handleChangePrice(e.target.value)}
          />
        )}
      </Grid>
      <Grid item md={1}>
        {!editPrice ? (
          <IconButton
            className={classes.deleteIcon}
            onClick={() => setEditPrice(true)}
            inputProps={{ className: classes.input }}
          >
            <AiFillEdit />
          </IconButton>
        ) : (
          <IconButton
            className={classes.deleteIcon}
            onClick={handleChangeNewAmount}
          >
            <BsCheckCircle />
          </IconButton>
        )}
      </Grid>

      <Grid item md={1}>
        <IconButton
          className={classes.editIcon}
          onClick={() => handleRemoveProduct(productDetail)}
        >
          <AiFillDelete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PriceCard;
