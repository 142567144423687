import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../constants/styleConstants";

const ClientStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 80,
    width: "100%",
  },
  clientsTitle: {
    color: theme.palette.primary.main,
    marginTop: 30,
  },

  searchBarContainer: {
    backgroundColor: theme.palette.primary.apple_green,
    height: 92,
    width: "90%",
    clear: "both",
    borderRadius: 10,
    marginTop: 90,
  },
  searchBarInputfield: {
    width: "70%",
    backgroundColor: theme.palette.primary.contrastText,
    border: "transparent",
    borderRadius: 10,
    "&:hover": {
      borderColor: "transparent",
    },
  },
  searchBarWithDropdown: {
    width: "45%",
    backgroundColor: theme.palette.primary.contrastText,
    border: "transparent",
    borderRadius: 10,
    "&:hover": {
      borderColor: "transparent",
    },
  },

  chooseRouteButton: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 140,
    height: 40,
    width: 140,
    marginTop: 20,
    textTransform: "none",
    borderRadius: 5,
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: 5,
    marginTop: 20,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 80,
    height: 40,
    width: 120,
    textTransform: "none",
  },
  massiveUpdate: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 80,
    height: 40,
    width: 200,
    marginTop: 20,
    textTransform: "none",
    borderRadius: 5,
  },
  addClientButton: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "right",
    marginRight: 180,
    height: 40,
    width: 200,
    marginTop: 20,
    textTransform: "none",
    borderRadius: 5,
  },
  paramsDropdown: {
    width: 150,
    marginLeft: 30,
  },
}));

export default ClientStyles;
