import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PayInvoiceStyles from "./PayInvoice.styles";
import { Close } from "@material-ui/icons";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
import { useSelector } from "react-redux";
import { selectUserId, selectUserName } from "../../../store/slices/user";
import AnimationContent from "../../../components/sharedComponents/AnimationContent";
import LoadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import { PAY_INVOICE_TEXT_TICKET } from "../../../constants/constantMessages";
import useGetRouteName from "../../../hooks/useGetRouteName";
import useGetFolioNumber from "../../../hooks/useGetFolioNumber";
const PayInvoice = (props) => {
  const {
    handleClose,
    invoiceInfo,
    handleOpenAlert,
    handleSubmitPayment,
    loading,
  } = props;
  const classes = PayInvoiceStyles();
  const { id_invoice, remaining_payment, total_amount } = invoiceInfo;
  const [payment, setPayment] = useState();
  const [comments, setComments] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [localization, setLocalization] = useState();
  //   const [loading, setLoading] = useState(false);
  const idUser = useSelector(selectUserId);
  const idPaymentFormat = useGetFolioNumber();
  const routeName = useGetRouteName();
  const username = useSelector(selectUserName);

  const handlePayment = (payment) => {
    if (parseInt(payment) > parseInt(remaining_payment)) {
      setError(true);
      setErrorMessage("La cantidad debe ser menor al adeudo");
    } else if (payment <= 0) {
      setError(true);
      setErrorMessage("La cantidad debe ser mayor a cero");
    } else {
      setError(false);
    }
    setPayment(payment);
    handleOpenAlert();
  };
  useEffect(() => {
    if (navigator.geolocation) {
      //check if geolocation is available
      navigator.geolocation.getCurrentPosition(function (position) {
        let location = position.coords;
        let { latitude, longitude } = location;
        setLocalization(`${latitude}, ${longitude}`);
      });
    } else {
      setLocalization("20.000948124862976, -102.27041548703968");
    }
  }, []);

  const handlePaymentLink = () => {
    const tiket_payment_info = PAY_INVOICE_TEXT_TICKET(
      invoiceInfo.name_client,
      invoiceInfo.id_invoice,
      routeName,
      idPaymentFormat,
      invoiceInfo.remaining_payment,
      payment,
      parseInt(invoiceInfo.remaining_payment) - parseInt(payment),
      username
    );

    let payload = {
      idPayment: idPaymentFormat,
      idInvoice: id_invoice,
      idUser: idUser,
      amount: payment,
      locationGPS: localization,
      comments: comments,
      textTicket: tiket_payment_info,
      printedTicket: false,
    };

    handleSubmitPayment(payload);
  };

  return (
    <Box className={classes.mainContainer}>
      {!loading ? (
        <React.Fragment>
          <IconButton
            onClick={handleClose}
            className={classes.closeIconContainerMain}
          >
            <Close />
          </IconButton>

          <CenteredContent direction="column">
            <Typography
              align="center"
              variant="subtitle2"
              className={classes.title}
            >
              Realizar Abono
            </Typography>
            <Typography
              align="center"
              variant="h3"
              className={classes.amountTitle}
            >
              Adeudo
            </Typography>
            <Typography align="center" variant="h2" className={classes.amount}>
              ${total_amount}
            </Typography>

            <Typography align="center" variant="h3" className={classes.pay}>
              Abono
            </Typography>
            <TextField
              value={payment}
              onChange={(e) => handlePayment(e.target.value)}
              variant="outlined"
              placeholder="$100"
              error={error}
              helperText={error && errorMessage}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Typography
              align="center"
              variant="h3"
              className={classes.restAmount}
            >
              Saldo restante
            </Typography>
            <Typography
              align="center"
              variant="h3"
              className={classes.restAmount}
            >
              {payment &&
              payment > 0 &&
              parseInt(payment) <= parseInt(remaining_payment)
                ? `${parseInt(remaining_payment) - parseInt(payment)}.00`
                : `${parseInt(remaining_payment)}.00`}
            </Typography>

            <Typography
              align="center"
              variant="subtitle2"
              className={classes.restAmount}
            >
              Comentarios(Opcional)
            </Typography>
            <TextField
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              variant="outlined"
              className={classes.commentsField}
              inputProps={{ className: classes.commentsMultiline }}
              multiline
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.paymentButton}
              disabled={!payment || error ? true : false}
              onClick={handlePaymentLink}
            >
              Abonar
            </Button>
          </CenteredContent>
        </React.Fragment>
      ) : (
        <CenteredContent style={{ height: "100%" }} direction="column">
          <AnimationContent animationFile={LoadingAnimation} />
          <Typography align="center" variant="h6">
            Realizando el abono
          </Typography>
          <Typography align="center" variant="body2">
            No cierre la pantalla{" "}
          </Typography>
        </CenteredContent>
      )}
    </Box>
  );
};

export default PayInvoice;
