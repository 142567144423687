import moment from "moment";

export const ERROR_MESSAGES = {
  TOKEN_EXPIRED: "Token caducado",
};

/**
 *
 * @param {*} clientName
 * @param {*} idInvoice
 * @param {*} route
 * @param {*} idAbono
 * @param {*} previousAmount
 * @param {*} payment
 * @param {*} userName
 * @returns
 */
/*


*/
export const CANCEL_INVOICE_TEXT_TICKET = (
  clientName,
  idInvoice,
  route,
  idAbono,
  previousAmount,
  payment,
  userName
) => {
  return (
    `BCLIENTE: ${clientName.toUpperCase()}\n` +
    `FACTURA/CTA: F${idInvoice}\n` +
    `FECHA: ${moment().format("yyyy-MM-DD HH:mm:ss")}\n` +
    `ZONA: ${route.toUpperCase()}\n` +
    "\n" +
    `NO. ABONO: ${idAbono}\n` +
    "\n" +
    `SALDO ANT: ${Math.round(previousAmount)}\n` +
    `ABONO: ${payment}\n
    ` +
    `SALDO: CANCELADO\n` +
    "\n" +
    `CANCELADO\n` +
    `RECUERDE SOLICITAR SU DOCUMENTO, A EL NUMERO TELEFONICO SENALADO.\n` +
    `TELEFONO: 3511224868 NAVICK\n` +
    `TELEFONO: 3513741495 NATURAL PRODUCTS\n` +
    "\n" +
    `AGRADECEN SU PREFERENCIA\n` +
    `NAVICK\n` +
    `NATURAL PRODUCTS\n` +
    `MUEBLERIAS ADE\n` +
    `LEROGA\n` +
    `MUNDO BEBE\n` +
    "\n" +
    "\n" +
    "--------------------------------\n" +
    `<C>FIRMA COBRADOR: ${userName.toUpperCase()}</C>\n` +
    "\n"
  );
};

export const PAY_INVOICE_TEXT_TICKET = (
  clientName,
  idInvoice,
  route,
  idAbono,
  previousAmount,
  payment,
  remainingPayment,
  userName
) => {
  return (
    `BCLIENTE: ${clientName.toUpperCase()}\n` +
    `FACTURA/CTA: F${idInvoice}\n` +
    `FECHA: ${moment().format("yyyy-MM-DD HH:mm:ss")}\n` +
    `ZONA: ${route.toUpperCase()}\n` +
    "\n" +
    `NO. ABONO: ${idAbono}\n` +
    "\n" +
    `SALDO ANT: ${Math.round(previousAmount)}\n` +
    `ABONO: ${payment}\n` +
    `SALDO: ${Math.round(remainingPayment)}\n` +
    "\n" +
    `RECUERDE EXIGIR TICKET Y FIRMA DE TARJETA. EN CASO DE NO RECIBIR EL TICKET, POR FAVOR REPORTARLO A LA EMPRESA Y SU ABONO SERA APLICADO A SU CUENTA Y SE LE REEMBOLSARA LA MISMA CANTIDAD.\n` +
    `LA EMPRESA NO SE RESPONSABILIZA DE LOS TRATOS VERBALES CON EL AGENTE.\n` +
    `AVISAR CAMBIO DE DOMICILIO EVITA GASTOS DE LOCALIZACION.\n` +
    `TELEFONO: 3511224868 NAVICK\n` +
    `TELEFONO: 3513741495 NATURAL PRODUCTS\n` +
    "\n" +
    `AGRADECEN SU PREFERENCIA\n` +
    `NAVICK\n` +
    `NATURAL PRODUCTS\n` +
    `MUEBLERIAS ADE\n` +
    `LEROGA\n` +
    `MUNDO BEBE\n` +
    "\n" +
    "\n" +
    "--------------------------------\n" +
    `<C>FIRMA COBRADOR: ${userName.toUpperCase()}</C>\n` +
    "\n"
  );
};


