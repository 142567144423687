import { makeStyles } from "@material-ui/core/styles";

const AdministratorStyles = makeStyles((theme) => ({
  mainContainer: {
   
  },
  welcomeTitle: {
    color: theme.palette.primary.main,
    marginTop: 30,
    marginLeft:90
  },
  welcomeName: {
    color: theme.palette.primary.dark,
    marginTop: 5,
    marginLeft:90
  },
  adminTime: {
    marginTop: 50,
  },
  adminMainContent:{
      marginTop:20
  }
}));

export default AdministratorStyles;
