import { Box, Grid, Typography, TextField, Button } from "@material-ui/core";
import createRouteStyles from "./CreateRoute.styles";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import React, { useState } from "react";
import { addRoute } from "../../../api/ApiRoutes";
import AnimationContent from "../../sharedComponents/AnimationContent";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
import { useSelector } from "react-redux";
import { selectAccessToken } from '../../../store/slices/clients/'
export const CreateRoute = (props) => {
  const { handleCloseModalCreateRoute } = props;
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const accessToken = useSelector(selectAccessToken)
  const handleSaved = () => {
    setTimeout(() => {
      setSaving(false);
      setSaved(true);
    }, 2000);

    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };
  const classes = createRouteStyles();
  const createRoute = async () => {
    try {
      setSaving(true);
      if (description.length > 0) {
        const result = await addRoute(description, accessToken);
        if (result.data.statusCode === 201) {
          handleSaved();
        }
      } else {
        setSaving(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      container
      className={classes.modalStyle}
      display="center"
      alignItems="center"
      justifyContent="center"
    >
      {!saving && !saved ? (
        <Grid item xs={12}>
          <Grid>
            <Typography variant="h1" className={classes.createRouteTitle}>
              Crear Ruta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.createRouteSubTitle}>
              Nombre de la Ruta
            </Typography>
            <TextField
              autoFocus
              inputProps={{ className: classes.descriptionInputStyle }}
              className={classes.textFieldInputStyle}
              variant="outlined"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }} //whenever the text field change, you save the value in state
              error={description.length <= 0 ? true : false}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button
                item
                className={classes.createRouteButton}
                startIcon={<MdOutlineAddCircleOutline />}
                onClick={createRoute}
              >
                CREAR
              </Button>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button
                item
                className={classes.cancelCreateRouteButton}
                onClick={handleCloseModalCreateRoute}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box>
          {saving && (
            <Box>
              <AnimationContent
                className={classes.animationStyle}
                animationFile={loadingAnimation}
                height={200}
                width={200}
              />
              <Typography
                variant="h3"
                align="center"
                className={classes.animationTitle}
              >
                Guardando Ruta
              </Typography>
              <Typography
                variant="h6"
                align="center"
                className={classes.animationTitle}
              >
                Por favor, no cierres la pantalla
              </Typography>
            </Box>
          )}
          {saved && (
            <Box>
              <AnimationContent
                className={classes.animationStyle}
                animationFile={successAnimation}
                height={200}
                width={200}
              />
              <Typography
                variant="h3"
                align="center"
                className={classes.animationTitle}
              >
                Ruta Guardada
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Grid>
  );
};

// export default CreateRoute;
