import axios from "axios";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const getUsers = (accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    url: `https://${REACT_APP_NAVICK_API_URL}/usuarios`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const changeUserStatus = (idUser, status, accessToken) => {
  const payload = {
    idUser,
    status,
  };
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `https://${REACT_APP_NAVICK_API_URL}/usuarios/updateStatus`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
export const createUser = (user, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    url: `https://${REACT_APP_NAVICK_API_URL}/usuarios`,
    data: user,
    body: JSON.stringify(user),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const updateUserInformation = (userInformation, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    url: `https://${REACT_APP_NAVICK_API_URL}/usuarios`,
    data: userInformation,
    body: JSON.stringify(userInformation),
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};

export const deleteUser = (idUser, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "DELETE",
    url: `https://${REACT_APP_NAVICK_API_URL}/usuarios/${idUser}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
