import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ViewClientInfoStyles from "./ViewClientInfo.styles";
import Map from "../../Map";
import { GENERAL_CONSTANTS } from "../../../constants/credentials";
import { Close } from "@material-ui/icons";
import {
  createNewClient,
  getClients,
  updateClient,
} from "../../../api/apiClients";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectClientInformation,
  setClients,
} from "../../../store/slices/clients";
//Animations Files
// import LoaderAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import { selectAvailableRoutes } from "../../../store/slices/routes";
import CenteredContent from "../../sharedComponents/CenteredContent";
import ModalLoader from "../../sharedComponents/ModalLoader";
const ViewClientInfo = (props) => {
  const { disabled, handleClose, createNew } = props;
  const [currentClientInfo, setCurrentClientInfo] = useState({});
  const userInformation = useSelector(selectClientInformation);
  const routes = useSelector(selectAvailableRoutes);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = useSelector(selectAccessToken);

  const dispatch = useDispatch();
  const priceType = [
    { id: 0, description: "ELIGE UNA OPCIÓN" },
    { id: 1, description: "DETALLE" },
    { id: 2, description: "GRAN MAYOREO" },
    { id: 3, description: "MAYOREO" },
    { id: 4, description: "MEDIO MAYOREO" },
  ];
  useEffect(() => {
    setCurrentClientInfo({
      idClient: createNew ? "" : userInformation.id_client,
      name: createNew ? "" : userInformation.name,
      idRoute: createNew ? "" : userInformation.id_route,
      street: createNew ? "" : userInformation.street,
      externalNumber: createNew ? "" : userInformation.external_number,
      internalNumber: createNew ? "" : userInformation.internal_number,
      neighborhood: createNew ? "" : userInformation.neighborhood,
      city: createNew ? "" : userInformation.city,
      state: createNew ? "" : userInformation.state,
      zipCode: createNew ? "" : userInformation.zip_code,
      personalPhoneNumber: createNew
        ? ""
        : userInformation.personal_phonenumber,
      homePhoneNumber: createNew ? "" : userInformation.home_phonenumber,
      email: createNew ? "" : userInformation.email,
      idPriceList: createNew ? "" : userInformation.id_price_list,
      status: createNew ? "" : userInformation.status,
      payDays: createNew ? "" : userInformation.pay_days,
      latitude: createNew ? "" : userInformation.latitude,
      longitude: createNew ? "" : userInformation.longitude,
      comments: createNew ? "" : userInformation.comments,
    });
  }, [
    createNew,
    userInformation.city,
    userInformation.comments,
    userInformation.email,
    userInformation.external_number,
    userInformation.home_phonenumber,
    userInformation.id_client,
    userInformation.id_price_list,
    userInformation.id_route,
    userInformation.internal_number,
    userInformation.latitude,
    userInformation.longitude,
    userInformation.name,
    userInformation.neighborhood,
    userInformation.pay_days,
    userInformation.personal_phonenumber,
    userInformation.state,
    userInformation.status,
    userInformation.street,
    userInformation.zip_code,
  ]);

  const {
    name,
    idRoute,
    street,
    externalNumber,
    internalNumber,
    neighborhood,
    city,
    state,
    zipCode,
    personalPhoneNumber,
    homePhoneNumber,
    email,
    idPriceList,
    status,
    payDays,
    latitude,
    longitude,
    comments,
  } = currentClientInfo;

  const createDisabled =
    name &&
    idRoute &&
    street &&
    externalNumber &&
    neighborhood &&
    city &&
    state &&
    zipCode &&
    personalPhoneNumber &&
    homePhoneNumber &&
    email &&
    status &&
    payDays &&
    latitude &&
    idPriceList &&
    longitude
      ? false
      : true;

  // const verifyToken = (response) => {
  //   if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
  //     dispatch(setIsAuth(false));
  //     dispatch(setAccessToken(""));
  //     history.push(ROUTES_CONSTANTS.LOGIN);
  //   } else {
  //     throw new Error("Something happen during the process");
  //   }
  // };

  const handleUpdate = () => {
    setLoading(true);
    updateClient(currentClientInfo, accessToken)
      .then((res) => {
        let closed = false;
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            closed = true;
            handleClose();
            window.location.reload();
          }, 2000);
        }, 3000);
        closed && setSuccess(false);
      })
      .catch(({ response }) => {
        // verifyToken(response);
      });
  };

  const handleCreateUser = () => {
    const newUser = {
      name,
      idRoute: parseInt(idRoute),
      street,
      externalNumber,
      internalNumber,
      neighborhood,
      city,
      state,
      zipCode: parseInt(zipCode),
      personalPhoneNumber,
      homePhoneNumber,
      email,
      idPriceList: parseInt(idPriceList),
      status,
      payDays: parseInt(payDays),
      latitude,
      longitude,
      comments,
    };

    setLoading(true);
    createNewClient(newUser, accessToken)
      .then((res) => {
        let closed = false;
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            closed = true;
            handleClose();
          }, 2000);
        }, 3000);
        closed && setSuccess(false);
        getClients(accessToken).then((res) => {
          dispatch(setClients(res.data.result));
        });
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        error?.response?.data?.result?.sqlResult?.message
          ? setErrorMessage(error?.response?.data?.result?.sqlResult?.message)
          : setErrorMessage(
              "La operación no pudo ser finalizada, intentalo nuevamente."
            );
        setTimeout(() => {
          handleClose();
        }, 3000);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentClientInfo((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const classes = ViewClientInfoStyles(props);
  return (
    <Box
      className={classes.mainContainer}
      display={(loading || success || error) && "flex"}
      justifyContent={(loading || success || error) && "center"}
      alignItems={(loading || success || error) && "center"}
    >
      {userInformation && !loading && !success && !error ? (
        <React.Fragment>
          <Grid container className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.titleModal}>
                Informacion del Cliente
              </Typography>
              <Box className={classes.closeIconContainer}>
                <IconButton onClick={handleClose} className={classes.closeIcon}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.title}>
                Información General
              </Typography>
            </Grid>
            {/* Form */}

            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Nombre
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="name"
                onChange={(e) => handleChange(e)}
                value={name && name}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Teléfono de casa
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="homePhoneNumber"
                onChange={(e) => handleChange(e)}
                value={homePhoneNumber && homePhoneNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Teléfono Celular
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="personalPhoneNumber"
                onChange={(e) => handleChange(e)}
                value={personalPhoneNumber && personalPhoneNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Correo electrónico
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="email"
                onChange={(e) => handleChange(e)}
                value={email && email}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Estado
              </Typography>
              <Select
                variant="outlined"
                name="status"
                value={parseInt(status)}
                onChange={(e) => handleChange(e)}
                inputProps={{ className: classes.input }}
                style={{ width: 100 }}
              >
                <MenuItem value={1}>Activo</MenuItem>
                <MenuItem value={0}>Inactivo</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Días de pago
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="payDays"
                onChange={(e) => handleChange(e)}
                value={payDays && payDays}
                inputProps={{ className: classes.input }}
                className={classes.textFieldSmall}
              />
            </Grid>

            <Grid item xs={4} md={2}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Tipo de precios
              </Typography>
              <Select
                variant="outlined"
                name="idPriceList"
                value={idPriceList ? parseInt(idPriceList) : 0}
                onChange={(e) => handleChange(e)}
                style={{ width: 200 }}
              >
                {priceType?.map((pt) => (
                  <MenuItem key={pt.id} value={pt.id}>
                    {pt.description}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Form */}

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className={`${classes.title} ${classes.addressInformation}`}
              >
                Dirección
              </Typography>
            </Grid>
            {/* Address information */}

            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Calle
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="street"
                onChange={(e) => handleChange(e)}
                value={street && street}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Número exterior
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="externalNumber"
                onChange={(e) => handleChange(e)}
                value={externalNumber && externalNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Número interior
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="internalNumber"
                onChange={(e) => handleChange(e)}
                value={internalNumber && internalNumber}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Colonia
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="neighborhood"
                value={neighborhood && neighborhood}
                onChange={(e) => handleChange(e)}
                inputProps={{ className: classes.input }}
                className={classes.textFieldLarge}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Municipio
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="state"
                onChange={(e) => handleChange(e)}
                value={state && state}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                CP
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="zipCode"
                onChange={(e) => handleChange(e)}
                value={zipCode && zipCode}
                inputProps={{ className: classes.input }}
                className={classes.textFieldSmall}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Población
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="city"
                onChange={(e) => handleChange(e)}
                value={city && city}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Observaciones
              </Typography>
              <TextField
                multiline={true}
                disabled={disabled}
                variant="outlined"
                name="comments"
                onChange={(e) => handleChange(e)}
                value={comments}
                inputProps={{ className: classes.inputMultiline }}
                className={classes.textFieldMultiline}
              />
            </Grid>
            {/* Address information */}
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className={`${classes.title} ${classes.addressInformation}`}
              >
                Ruta
              </Typography>
            </Grid>
            {/* Route information */}

            <Grid item xs={3} md={3}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Ruta designada
              </Typography>

              <Select
                variant="outlined"
                name="idRoute"
                value={parseInt(idRoute)}
                onChange={(e) => handleChange(e)}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              >
                {routes &&
                  routes.map((route) => (
                    <MenuItem key={route.id_route} value={route.id_route}>
                      {route.description}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Latitud
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="latitude"
                onChange={(e) => handleChange(e)}
                value={latitude && latitude}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Typography
                variant="subtitle1"
                className={
                  disabled
                    ? classes.inputTitleDisabled
                    : classes.inputTitleEnabled
                }
              >
                Longitud
              </Typography>
              <TextField
                disabled={disabled}
                variant="outlined"
                name="longitude"
                onChange={(e) => handleChange(e)}
                value={longitude && longitude}
                inputProps={{ className: classes.input }}
                className={classes.textFieldMedium}
              />
            </Grid>
            <Grid item xs={3} md={3} />

            {!latitude && !longitude ? (
              <CenteredContent className={classes.googleMapNotAssigned}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    Este usuario no tiene una ruta asignada
                  </Typography>
                </Grid>
              </CenteredContent>
            ) : (
              <Grid item xs={12}>
                <Map
                  googleMapURL={GENERAL_CONSTANTS.URL_API_MAPS}
                  containerElement={
                    <Box className={classes.googleMapsContainer} />
                  }
                  mapElement={<Box className={classes.googleMapsElement} />}
                  loadingElement={<p>Cargando...</p>}
                  isMarkerShown
                  lat={parseFloat(latitude)}
                  long={parseFloat(longitude)}
                />
              </Grid>
            )}
            {!disabled && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={createNew && createDisabled}
                  className={classes.saveButton}
                  onClick={createNew ? handleCreateUser : handleUpdate}
                >
                  {!createNew ? "Guardar" : "Crear Usuario"}
                </Button>
              </Grid>
            )}

            {/* Route information */}
          </Grid>
        </React.Fragment>
      ) : (
        !loading &&
        !success &&
        !error && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h1">Este usuario no tiene datos</Typography>
          </Box>
        )
      )}
      {(loading || success || error) && (
        <Box>
          <Typography
            variant="h1"
            align="center"
            className={classes.savingData}
          >
            {loading && "Guardando datos"}
            {success && "¡Listo!"}
            {error && "¡Oh no!"}
          </Typography>
          <ModalLoader
            isLoading={loading}
            isSuccessfully={success}
            onError={error}
          />
          <Typography variant="body1" align="center">
            {loading && "Por favor espera, no cierres la pantalla."}
            {success && "Operación realizada con exito"}
            {error && errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ViewClientInfo;
