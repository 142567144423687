import React, { useEffect } from "react";
import { getAvailableRoutes } from "../../api/ApiRoutes";
import DrawerMenu from "../../components/DrawerMenu/Index";
import { useDispatch } from "react-redux";
import { setAvailableRoutes } from "../../store/slices/routes";
import { useSelector } from "react-redux";
import { selectLogedUserInformation, setIsAuth } from "../../store/slices/user";
import { Box, Grid, Typography } from "@material-ui/core";
// import AnimationDashboard from "../../assets/lottieFiles/dashboardAnimation.json";
// import MetricsAnimation from "../../assets/lottieFiles/metricsAnimation.json";
import AdministratorStyles from "./Administrator.styles";
import { selectAccessToken, setAccessToken } from "../../store/slices/clients";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Administrator = () => {
  const dispatch = useDispatch();
  const classes = AdministratorStyles();
  const userInformation = useSelector(selectLogedUserInformation);
  const accesToken = useSelector(selectAccessToken);
  const history = useHistory();
  useEffect(() => {
    getAvailableRoutes(accesToken)
      .then((res) => {
        res.data.result && dispatch(setAvailableRoutes(res.data.result));
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  }, [accesToken, dispatch, history]);

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.welcomeTitle}>
          Bienvenido
        </Typography>
        <Typography variant="h6" className={classes.welcomeName}>
          {userInformation && userInformation.nombre}
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.adminMainContent}
        >
          <Grid item xs={6} className={classes.adminTime}>
            {/* <ContentAnimationCard
              cardTitle="Aprovecha tu tiempo, de una mejor manera."
              cardDescription={` Con NAVICK, utiliza tu tiempo en la administración de tu sistema
                solamente. Observa, crea y modifica la información de tus
                clientes, usuarios y rutas.`}
              animationFile={AnimationDashboard}
              widthAnimation={400}
            /> */}
          </Grid>
          <Grid item xs={6} className={classes.adminTime}>
            {/* <ContentAnimationCard
              cardTitle=" Corroborá tus métricas y observa tus resultados."
              cardDescription="Navega por todos los menús, y observa tus facturas, tus movimientos y demás información dentro del sistema de NAVICK"
              animationFile={MetricsAnimation}
              widthAnimation={400}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </DrawerMenu>
  );
};

export default Administrator;
