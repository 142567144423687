import { makeStyles } from "@material-ui/core/styles";

const UserStylesPage = makeStyles((theme) => ({
  usersSectionTitle: {
    color: theme.palette.primary.main,
    marginTop: 30,
  },
  mainContainer: {
    marginLeft: 80,
  },
  saveUserButton: {
    marginTop: 50,
    borderRadius: 10,
    height: 40,
  },
}));

export default UserStylesPage;
