import { makeStyles } from "@material-ui/core";
import { FONT_CONSTANTS } from "../../../constants/styleConstants";
import ImageMap from "../../../assets/images/mapImage.png"; // Import using relative path

const cardRoutesStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 300,
    height: 284,
    boxShadow: "0px 4px 4px rgba(177, 244, 216, 0.35)",
    borderRadius: 20,
    marginTop: 25,
    cursor: "pointer",
  },
  mapContainer: {
    width: "95%",
    height: 145,
    borderRadius: 10,
    marginTop: 12,
    marginLeft: 8,
    marginRight: 8,
    backgroundImage: `url(${ImageMap})`,
    backgroundSize: "90%",
  },
  title: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD,
    fontSize: 18,
  },
  subtitle: {
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_MEDIUM,
    fontSize: 10,
    color: theme.palette.primary.gray_30,
    marginTop: 5,
  },
  showMapButton: {
    backgroundColor: theme.palette.primary.green_10,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    textTransform: "none",
    color: theme.palette.primary.white,
    marginTop: 5,
    borderRadius: 10,
  },
  deleteRouteButtonContainer: {
    borderRadius: "50%",
    boxShadow: "0px 1px 3px rgba(79, 172, 150, 0.19)",
    height: 45,
    width: 45,
    marginTop: -20,
    marginLeft: 20,
  },
  deleteRouteButton: {
    height: 20,
    width: 20,
  },
  deleteRouteBox: {
    paddingTop: 40,
  },
  googleMapsContainer: {
    width: 283,
    height: 160,
    marginTop: 20,
  },
  googleMapsElement: {
    height: "100%",
    borderRadius: 20,
  },
}));

export default cardRoutesStyles;
