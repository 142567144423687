import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import CenteredContent from "../../components/sharedComponents/CenteredContent";
import SplashScreenStyles from "./SplashScreen.styles";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
const SplashScreen = () => {
  const classes = SplashScreenStyles();
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push(ROUTES_CONSTANTS.LOGIN);
    }, 4000);
  }, [history]);

  return (
    <CenteredContent direction="column" className={classes.mainContainer}>
      <Box display="flex" flexDirection="row">
        <Typography variant="h1" className={classes.navickText}> N</Typography>
        <Typography variant="h1" className={classes.navickText}> A</Typography>
        <Typography variant="h1" className={classes.navickText}> V</Typography>
        <Typography variant="h1" className={classes.navickText}> I</Typography>
        <Typography variant="h1" className={classes.navickText}> C</Typography>
        <Typography variant="h1" className={classes.navickText}> K</Typography>
        {/* <span className={classes.navickText}> A</span>
        <span className={classes.navickText}> V</span>
        <span className={classes.navickText}> I</span>
        <span className={classes.navickText}> C</span>
        <span className={classes.navickText}> K</span> */}
      </Box>
    </CenteredContent>
  );
};

export default SplashScreen;
