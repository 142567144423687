import { makeStyles } from "@material-ui/core/styles";

const SplashScreenStyles = makeStyles((theme) => ({
  "@keyframes fadeIn": {
    "0%": { opacity: 0, transform: `translateY(-100px)` },
    "25%": { opacity: 1, transform: `translateY(0px)` },
    "75%": { opacity: 1, transform: `translateY(0px)` },
    "100%": { opacity: 0, transform: `translateY(-100px)` },
  },
  mainContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
  },
  welcomeText: {
    animation: "$fadeIn .8s ease-in-out",
    fontSize: 50,
    fontWeight: 800,
    marginBottom: 20,
  },
  navickText: {
    animationName:"$fadeIn",
    animationDuration:"4s",
    animationFillMode:"forwards",
    animationIterationCount:"infinite",
    fontSize: 150,
    fontWeight: 800,
  },
}));

export default SplashScreenStyles;
