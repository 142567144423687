import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
export default function DatePicker(props) {
  const { selectedDate, handleChangeDate, dateStyles } = props;

  // The first commit of Material-UI

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        lang="es"
        className={dateStyles && dateStyles}
        margin="normal"
        id="date-picker-dialog"
        format="MM/dd/yyyy"
        value={selectedDate}
        onChange={handleChangeDate}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
