import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DrawerMenu from "../../components/DrawerMenu/Index";
import ClientStyles from "./Clients.styles";
import { MdAddCircleOutline } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import DataTable from "../../components/sharedComponents/DataTable";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import ViewClientInfo from "../../components/ModalBodies/ViewClientInfo";
import DeleteUser from "../../components/ModalBodies/DeleteUser";
import { getClients, updateClientsRoutesMassive } from "../../api/apiClients";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectClients,
  selectClientsToUpdateRoute,
  setAccessToken,
  setClientInformation,
  setClients,
  setClientsToUpdateRoute,
} from "../../store/slices/clients";
import { FaMapMarkerAlt } from "react-icons/fa";
import ChooseRouteBodyModal from "../../components/ModalBodies/ChooseRouteBodyModal";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { setIsAuth } from "../../store/slices/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InvoicesPaymentsInformation from "../../components/ModalBodies/InvoicesPaymentsInformation";
import { selectAvailableRoutes } from "../../store/slices/routes";
// import { selectAvailableRoutes } from "../../store/slices/routes";

const Clients = () => {
  const [loadingInfo, setLoadingInfo] = useState(true);
  const clientsInfo = useSelector(selectClients);
  //View Modal variables
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openViewInfo, setopenViewInfo] = useState(false);
  const [openPaymentInvoicesInfo, setopenPaymentInvoicesInfo] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openChooseRouter, setOpenChooseRoute] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [massiveUpdate, setMassiveUpdate] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [successLoad, setSuccessLoad] = useState(false);
  const [clientIdValue, setClientIdValue] = useState("");
  const [parameterToSearch, setParameterToSearch] = useState("name");
  const clientsMassiveUpdate = useSelector(selectClientsToUpdateRoute);
  const accesToken = useSelector(selectAccessToken);
  const history = useHistory();
  const [selectedRoute, setSelectedRoute] = useState(0);
  //dispatch
  const dispatch = useDispatch();

  const handleOpenCreateClient = () => {
    setOpenCreateClient(true);
  };

  const handleOpenViewInfo = (client) => {
    dispatch(setClientInformation(client));
    setopenViewInfo(true);
  };

  const handleOpenEditUser = (client) => {
    dispatch(setClientInformation(client));
    setOpenEditUser(true);
  };
  const handleOpenChooseRoute = () => setOpenChooseRoute(true);
  const handleCloseChooseRoute = () => setOpenChooseRoute(false);

  const handleOpenDeleteUser = (client) => {
    dispatch(setClientInformation(client));
    setOpenDeleteUser(true);
  };

  const handleCloseViewInfo = () => setopenViewInfo(false);
  const handleCloseCreateClient = () => setOpenCreateClient(false);
  const handleCloseEdituser = () => setOpenEditUser(false);
  const handleCloseDeleteuser = () => setOpenDeleteUser(false);
  const handleOpenUserInvoicesInformation = (clientId) => {
    setopenPaymentInvoicesInfo(true);
    setClientIdValue(clientId);
  };
  const handleCloseUserInvoicesInformation = () => {
    setopenPaymentInvoicesInfo(false);
    setClientIdValue("");
  };

  const handleUpdateRoutes = async (idRoute) => {
    setLoadUpdate(true);
    const updatedClientsRoutes =
      (await clientsMassiveUpdate) &&
      clientsMassiveUpdate.length > 0 &&
      clientsMassiveUpdate.map((client) => ({
        idClient: parseInt(client),
        idRoute: parseInt(idRoute),
      }));
    updateClientsRoutesMassive(updatedClientsRoutes, accesToken)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setTimeout(() => {
            setLoadUpdate(false);
            setSuccessLoad(true);
            getClients(accesToken)
              .then((res) => {
                dispatch(setClients(res.data.result));
                window.location.reload();
              })
              .catch(({ response }) => {
                if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
                  dispatch(setIsAuth(false));
                  dispatch(setAccessToken(""));
                  history.push(ROUTES_CONSTANTS.LOGIN);
                }
              });
          }, 5000);
        }
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      })
      .finally((error) => {
        setMassiveUpdate(false);
        console.log(error);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoadingInfo(true);
      try {
        const res = await getClients(accesToken);
        if (res.data.statusCode === 200) {
          setFilteredClients(res.data.result);
          dispatch(setClients(res.data.result));
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingInfo(false);
    };
    fetchData();
  }, [accesToken, dispatch]);

  useEffect(() => {
    dispatch(setClientsToUpdateRoute([]));
  }, [dispatch]);

  const headCells = [
    { id: "check", numeric: true, disablePadding: false, label: "" },
    { id: "ID", numeric: true, disablePadding: false, label: "ID Cliente" },
    { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
    { id: "route", numeric: false, disablePadding: false, label: "Ruta" },
    {
      id: "Municipality",
      numeric: true,
      disablePadding: false,
      label: "Municipio",
    },
    { id: "cp", numeric: true, disablePadding: false, label: "Código Postal" },
    { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
  ];
  const availableRoutes = useSelector(selectAvailableRoutes);

  const getRouteName = (idRoute) => {
    const itemIndex = availableRoutes.findIndex(
      (routes) => parseInt(routes.id_route) === idRoute
    );
    if (itemIndex >= 0) {
      return availableRoutes[itemIndex].description;
    }
  };

  const handleSearch = (field_name) => {
    let filteredClients;
    switch (parameterToSearch) {
      case "name":
        filteredClients = !field_name
          ? clientsInfo
          : clientsInfo.filter((cliente) =>
              cliente.name.toLowerCase().includes(field_name.toLowerCase())
            );
        break;
      case "route":
        const filteredByRoute = clientsInfo.filter(
          (client) => getRouteName(client.id_route) === selectedRoute
        );
        filteredClients = !field_name
          ? filteredByRoute
          : filteredByRoute.filter((cliente) =>
              cliente.name.toLowerCase().includes(field_name.toLowerCase())
            );
        break;
      case "municipaly":
        filteredClients = !field_name
          ? clientsInfo
          : clientsInfo.filter((cliente) =>
              cliente.city.toLowerCase().includes(field_name.toLowerCase())
            );
        break;
      case "id":
        filteredClients = !field_name
          ? clientsInfo
          : clientsInfo.filter((cliente) =>
              cliente.id_client.toLowerCase().includes(field_name.toLowerCase())
            );
        break;

      default:
        break;
    }

    setFilteredClients(filteredClients);
  };

  const handleOpenMassiveUpdate = () => {
    setMassiveUpdate(!massiveUpdate);
  };

  const handleChangeParameter = (e) => {
    if (e.target.value === "route") {
    }
    setParameterToSearch(e.target.value);
  };

  const params = [
    {
      id: 1,
      label: "ID",
      value: "id",
    },
    {
      id: 2,
      label: "Nombre",
      value: "name",
    },
    {
      id: 3,
      label: "Ruta",
      value: "route",
    },
    {
      id: 4,
      label: "Municipio",
      value: "municipaly",
    },
    // {
    //   id: 5,
    //   label: "Código postal",
    //   value: "cp",
    // },
  ];

  const handleSelectedRoute = (routeName) => {
    setFilteredClients(
      clientsInfo.filter(
        (client) => getRouteName(client.id_route) === routeName
      )
    );

    setSelectedRoute(routeName);
  };

  const renderRouteDrowpdown = () => {
    if (parameterToSearch === "route") {
      return (
        <FormControl className={classes.routeDropdownContainer}>
          <Select
            className={classes.paramsDropdown}
            variant="outlined"
            value={selectedRoute}
            onChange={(e) => handleSelectedRoute(e.target.value)}
            inputProps={{
              name: "route",
              id: "route-name",
            }}
          >
            <MenuItem value={0}>
              Elige una ruta
            </MenuItem>
            {availableRoutes.length > 0 ? (
              availableRoutes.map((route) => (
                <MenuItem key={route.id_route} value={route.description}>
                  {route.description}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="0">No hay rutas disponibles</MenuItem>
            )}
          </Select>
        </FormControl>
      );
    }
  };
  const classes = ClientStyles();
  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.clientsTitle}>
          Listado de clientes
        </Typography>
        <Typography variant="subtitle2" className={classes.totalClients}>
          {clientsInfo?.length} clientes
        </Typography>

        {!massiveUpdate && (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              startIcon={<MdAddCircleOutline />}
              className={classes.addClientButton}
              onClick={handleOpenCreateClient}
            >
              Agregar Cliente
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FaMapMarkerAlt />}
              className={classes.massiveUpdate}
              onClick={handleOpenMassiveUpdate}
            >
              Cambio de ruta
            </Button>
          </React.Fragment>
        )}
        {massiveUpdate && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              // startIcon={<FaMapMarkerAlt />}
              disabled={
                clientsMassiveUpdate && clientsMassiveUpdate.length <= 0
              }
              className={classes.chooseRouteButton}
              onClick={handleOpenChooseRoute}
            >
              Elegir ruta
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={() => setMassiveUpdate(false)}
            >
              Cancelar
            </Button>
          </Box>
        )}

        <Box
          className={classes.searchBarContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            name="searchbar"
            variant="outlined"
            placeholder="Buscar cliente por nombre"
            onChange={(e) => handleSearch(e.target.value.toLowerCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="contrasena" edge="start">
                    <BsSearch />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className={
              parameterToSearch === "route"
                ? classes.searchBarWithDropdown
                : classes.searchBarInputfield
            }
          />

          <Select
            value={parameterToSearch}
            onChange={handleChangeParameter}
            variant="outlined"
            className={classes.paramsDropdown}
          >
            {params &&
              params.length > 0 &&
              params.map((param) => (
                <MenuItem key={param.id} value={param.value}>
                  {param.label}
                </MenuItem>
              ))}
          </Select>
          {renderRouteDrowpdown()}
        </Box>
        {filteredClients && filteredClients.length > 0 && (
          <DataTable
            rows={filteredClients}
            loading={loadingInfo}
            headCells={headCells}
            openViewInfo={handleOpenViewInfo}
            openEditUser={handleOpenEditUser}
            openDeleteUser={handleOpenDeleteUser}
            openPaymentInvoicesInfo={handleOpenUserInvoicesInformation}
            massiveUpdate={massiveUpdate}
          />
        )}
      </Box>
      {/* Modals */}
      <ModalComponent
        handleClose={handleCloseCreateClient}
        open={openCreateClient}
        body={
          <ViewClientInfo
            disabled={false}
            handleClose={handleCloseCreateClient}
            createNew={true}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseViewInfo}
        open={openViewInfo}
        body={
          <ViewClientInfo disabled={true} handleClose={handleCloseViewInfo} />
        }
      />
      <ModalComponent
        handleClose={handleCloseEdituser}
        open={openEditUser}
        body={
          <ViewClientInfo disabled={false} handleClose={handleCloseEdituser} />
        }
      />
      <ModalComponent
        handleClose={handleCloseDeleteuser}
        open={openDeleteUser}
        body={<DeleteUser handleClose={handleCloseDeleteuser} />}
      />
      <ModalComponent
        handleClose={handleCloseChooseRoute}
        open={openChooseRouter}
        body={
          <ChooseRouteBodyModal
            handleClose={handleCloseChooseRoute}
            handleUpdateRoutes={handleUpdateRoutes}
            loading={loadUpdate}
            success={successLoad}
          />
        }
      />
      <ModalComponent
        handleClose={handleCloseUserInvoicesInformation}
        open={openPaymentInvoicesInfo}
        body={
          <InvoicesPaymentsInformation
            handleClose={handleCloseUserInvoicesInformation}
            clientId={clientIdValue}
          />
        }
      />
      {/* Modals */}
    </DrawerMenu>
  );
};

export default Clients;
