import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import PositionMarkerWhiteIcon from '../../../assets/icons/zone-icon.png'
import PositionMarkerGrayIcon from '../../../assets/icons/zone-icon-gray.png'

const PositionGrayIcon = () => <img src={PositionMarkerGrayIcon} alt='position-icon' />
const PositionWhiteIcon = () => <img src={PositionMarkerWhiteIcon} alt='position-icon' />

const PaymentZones = (props) => {

  const { zones, classes: c, selectEvent } = props;

  const [buttonsColor, setButtonsColor] = useState(() => {
    return zones.map((i) => false);
  });

  const handleSelectButton = (e, i, d, desc) => {
    setButtonsColor((prev) => {
      return prev.map((button, index) => {
        if (index === i) {
          return true;
        }
        return false;
      });
    });
    selectEvent(d, desc);
  }

  return (
    <Box className={c.zonesContainer}>
      {zones.map((item, index) => {
        return (
          <Button
            variant='contained'
            className={!buttonsColor[index] ? c.buttonZone : c.buttonZoneSelected}
            key={item.id_route}
            startIcon={!buttonsColor[index] ? <PositionGrayIcon /> : <PositionWhiteIcon />}
            disableElevation
            onClick={(e) => { handleSelectButton(e, index, item.id_route, item.description) }}
          >
            {item.description}
          </Button>
        )
      })}
    </Box>
  )
}

export default PaymentZones