import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../../components/DrawerMenu/Index";
import AuditStyles from "./Audits.styles";
import { selectAvailableRoutes } from "../../store/slices/routes";
import { CreateTablePDF } from "../../utils/generalUtils";
import { getAuditReportsByRoute } from "../../api/apiClients";
import {
  resetAuditInformation,
  selectAccessToken,
  selectAuditInformation,
  selectAuditRouteSelected,
  setAccessToken,
  setAuditInformation,
  setAuditRouteSelected,
} from "../../store/slices/clients";
import { formatNumber } from "../../utils/formatUtils";
import { ERROR_MESSAGES } from "../../constants/constantMessages";
import { ROUTES_CONSTANTS } from "../../constants/routesConstants";
import { setIsAuth } from "../../store/slices/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Audits = () => {
  const classes = AuditStyles();
  const availableRoutes = useSelector(selectAvailableRoutes);
  const auditInformation = useSelector(selectAuditInformation);
  const selectedAuditRoute = useSelector(selectAuditRouteSelected);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const history = useHistory()
  const handleSelectedRoute = (route) => {
    dispatch(setAuditRouteSelected(route));
    getAuditReportsByRoute(route, accessToken)
      .then((res) => {
        dispatch(setAuditInformation(res.data.result));
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };

  useEffect(() => {
    dispatch(resetAuditInformation());
  }, [dispatch]); 

  return (
    <DrawerMenu>
      <Box className={classes.mainContainer}>
        <Typography variant="h1" className={classes.auditTitle}>
          {" "}
          Auditorias{" "}
        </Typography>

        <Typography variant="h6" className={classes.subtitle}>
          Selecciona la ruta para la auditoria
        </Typography>

        <FormControl>
          <Select
            className={classes.routeDropdown}
            variant="outlined"
            value={selectedAuditRoute}
            onChange={(e) => handleSelectedRoute(e.target.value)}
            inputProps={{
              name: "route",
              id: "route-name",
            }}
          >
            {availableRoutes.length > 0 ? (
              availableRoutes.map((route) => (
                <MenuItem key={route.id_route} value={route.id_route}>
                  {route.description}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="0">No hay rutas disponibles</MenuItem>
            )}
          </Select>
        </FormControl>

        <Box className={classes.createPDFButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => CreateTablePDF("#audit-table")}
            disabled={auditInformation.length <= 0}
          >
            Imprimir
          </Button>
        </Box>

        <TableContainer>
          <Table id="audit-table">
            <TableHead>
              <TableRow>
                <TableCell> ID</TableCell>
                <TableCell> Nombre</TableCell>
                <TableCell> Saldo</TableCell>
                <TableCell> Abono</TableCell>
                <TableCell> Dirección</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditInformation ? (
                auditInformation.map((info) => (
                  <TableRow key={info.v_id_client}>
                    <TableCell>
                      {info.v_id_client}
                    </TableCell>
                    <TableCell>{info.v_name ? info.v_name : "SN"}</TableCell>
                    <TableCell>
                      ${formatNumber(info.v_remaining_payment)}
                    </TableCell>
                    <TableCell>{info.v_created_at}</TableCell>
                    <TableCell>{info.v_address}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No hay datos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DrawerMenu>
  );
};

export default Audits;
