import { makeStyles } from "@material-ui/core/styles";


const routeMapStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 600,
        width: 800,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        padding: 20,
      },
      mapContainer: {
        width: "100%",
        height: 500,
        borderRadius: 10,
        marginTop: 12,
        marginLeft: 8,
        marginRight: 8, 
        //backgroundImage: `url(${ImageMap})`,
        backgroundSize:"90%",    
      },
      googleMapsContainer: {
        width: "100%",
        height: "100%",
        marginTop: 20,
      },
      googleMapsElement: {
        height: "100%",
        borderRadius: 20,
      },
}));

export default routeMapStyles;
