import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAvailableRoutes } from "../../../store/slices/routes";
import AnimationContent from "../../sharedComponents/AnimationContent";
import CenteredContent from "../../sharedComponents/CenteredContent";
import chooseBodyModalStyles from "./ChooseBodyModal.styles";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import successAnimation from "../../../assets/lottieFiles/successAnimation.json";
import { setClientsToUpdateRoute } from "../../../store/slices/clients";

const ChooseRouteBodyModal = (props) => {
  const { handleClose, handleUpdateRoutes, loading, success } = props;
  const classes = chooseBodyModalStyles();
  const availableRoutes = useSelector(selectAvailableRoutes);
  const [selectedRoute, setSelectedRoute] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSelectedRoute(event.target.value);
  };

  if (success) {
    setTimeout(() => {
      handleClose();
      dispatch(setClientsToUpdateRoute([]));
      
    }, 2000);
  }
  return (
    <Box className={classes.mainContainer}>
      {loading || success ? (
        <CenteredContent direction="column">
          <AnimationContent
            className={classes.animationStyle}
            animationFile={success ? successAnimation : loadingAnimation}
            height={200}
            width={200}
          />
          <Typography variant="subtitle1">Actualizando rutas</Typography>
        </CenteredContent>
      ) : (
        <React.Fragment>
          <Typography align="center" variant="h3" className={classes.mainTitle}>
            Selecciona la ruta
          </Typography>
          <Typography
            align="center"
            variant="body2"
            className={classes.mainSubtitle}
          >
            Todos los usuarios seleccionados, serán cambiados a esta ruta
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              value={selectedRoute}
              onChange={handleChange}
              variant="outlined"
              className={classes.routesDropdown}
            >
              {availableRoutes &&
                availableRoutes.length > 0 &&
                availableRoutes.map((route) => (
                  <MenuItem value={route.id_route} key={route.id_route}>
                    {route.description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.updateData}
              onClick={() => handleUpdateRoutes(selectedRoute)}
              disabled={!selectedRoute}
            >
              Actualizar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ChooseRouteBodyModal;
