import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Cancel, Close, DeleteForever } from "@material-ui/icons";
import React, { useState } from "react";
import DeleteUserStyles from "./DeleteUser.styles";
import DeleteAnimation from "../../../assets/lottieFiles/DeleteAnimation.json";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccessToken,
  selectClientInformation,
  setAccessToken,
} from "../../../store/slices/clients";
import { updateClientStatus } from "../../../api/apiClients";
import AnimationContent from "../../sharedComponents/AnimationContent";
import NotFoundAnimation from "../../../assets/lottieFiles/NotFoundAnimation.json";
import { ERROR_MESSAGES } from "../../../constants/constantMessages";
import { setIsAuth } from "../../../store/slices/user";
import { ROUTES_CONSTANTS } from "../../../constants/routesConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const DeleteUser = (props) => {
  const { handleClose } = props;
  const clientInfo = useSelector(selectClientInformation);
  const classes = DeleteUserStyles();
  const clientInformation = useSelector(selectClientInformation);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeStatus = (status) => {
    setDeleting(true);
    let newStatus;
    if (status && status === "i") {
      newStatus = 0;
    } else {
      newStatus = 1;
    }

    const { id_client } = clientInformation;

    updateClientStatus(id_client, newStatus, accessToken)
      .then((res) => {
        let closed = false;
        setTimeout(() => {
          setDeleting(false);
          setDeleted(true);
          setTimeout(() => {
            closed = true;
            handleClose();
            window.location.reload();
          }, 2000);
        }, 3000);
        if (closed) {
          setDeleted(false);
        }
      })
      .catch(({ response }) => {
        if (response.data.message === ERROR_MESSAGES.TOKEN_EXPIRED) {
          dispatch(setIsAuth(false));
          dispatch(setAccessToken(""));
          history.push(ROUTES_CONSTANTS.LOGIN);
        }
      });
  };

  const isCenter = deleting || deleted || clientInfo.status !== 1;

  return (
    <Box
      className={classes.mainContainer}
      display={isCenter && "flex"}
      justifyContent={isCenter && "center"}
      alignItems={isCenter && "center"}
    >
      {!deleting && !deleted ? (
        <React.Fragment>
          {clientInfo.status === 1 ? (
            <React.Fragment>
              <div
                className={classes.closeIconContainerMain}
                onClick={handleClose}
              >
                <IconButton>
                  <Close />
                </IconButton>
              </div>
              <Typography
                variant="h6"
                align="center"
                className={classes.cardTitle}
              >
                ¿Estas seguro de eliminar este cliente?
              </Typography>
              <Typography
                variant="h6"
                align="center"
                className={classes.cardUserName}
              >
                {clientInfo.name && clientInfo.name}
              </Typography>
              <Box className={classes.buttonsContainer}>
                <Button
                  startIcon={<DeleteForever />}
                  variant="contained"
                  color="primary"
                  className={classes.deleteButton}
                  onClick={() => handleChangeStatus("i")}
                >
                  Eliminar
                </Button>
                <Button
                  startIcon={<Cancel />}
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <Box display="center" alignItems="center" justifyContent="center">
              <div className={classes.closeIconContainer} onClick={handleClose}>
                <IconButton>
                  <Close />
                </IconButton>
              </div>
              <AnimationContent
                animationFile={NotFoundAnimation}
                height={150}
                width={150}
              />
              <Typography
                variant="h6"
                align="center"
                className={classes.userDeletedTitle}
              >
                Este cliente ya está dado de baja
              </Typography>
              <Typography
                variant="body2"
                align="center"
                className={classes.userDeletedSubtitle}
              >
                Si necesitas actualizar su estado, hazlo desde el apartado de
                edición
              </Typography>
            </Box>
          )}
        </React.Fragment>
      ) : (
        <Box>
          {deleting && (
            <AnimationContent
              animationFile={loadingAnimation}
              height={150}
              width={150}
            />
          )}

          {deleted && (
            <AnimationContent
              animationFile={DeleteAnimation}
              height={150}
              width={150}
            />
          )}

          <Typography
            variant="body2"
            className={classes.subtitleText}
            align="center"
          >
            {deleting ? "Eliminando Cliente.." : "Cliente Eliminado"}{" "}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DeleteUser;
