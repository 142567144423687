import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AlertMesssageStyles from "./AlertMessage.styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AlertMessage = (props) => {
  const { open, handleClose, type, message } = props;
  const classes = AlertMesssageStyles();

  const VARIANT_ALERTS = {
    success: (
      <Alert onClose={handleClose} severity="success">
        {message}
      </Alert>
    ),
    info: (
      <Alert onClose={handleClose} severity="info">
        {message}
      </Alert>
    ),
    warning: (
      <Alert onClose={handleClose} severity="warning">
        {message}
      </Alert>
    ),
    error: (
      <Alert onClose={handleClose} severity="error">
        {message}
      </Alert>
    ),
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {VARIANT_ALERTS[type]}
      </Snackbar>
    </div>
  );
};

export default AlertMessage;
