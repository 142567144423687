import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DataTableStyles from "./DataTable.styles";
import EnhancedTableHead from "./EnhancedTableHead";
import { Box, Checkbox, Chip, IconButton } from "@material-ui/core";
import { MdOutlineRemoveRedEye, MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { Skeleton } from "@material-ui/lab";
// import { getRouteName } from "../../../utils/apiUtils";
import { useDispatch, useSelector } from "react-redux";
import { selectAvailableRoutes } from "../../../store/slices/routes";
import { setClientsToUpdateRoute } from "../../../store/slices/clients";
import { MdPayment } from "react-icons/md";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DataTable(props) {
  const {
    rows,
    loading,
    headCells,
    openViewInfo,
    openEditUser,
    openDeleteUser,
    massiveUpdate,
    openPaymentInvoicesInfo,
  } = props;
  const classes = DataTableStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const availableRoutes = useSelector(selectAvailableRoutes);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const dispatch = useDispatch();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleState = (state) => {
    if (state === 1) {
      return <Chip label="Activo" className={classes.activeChipState} />;
    } else {
      return <Chip label="Inactivo" className={classes.inactiveChipState} />;
    }
  };
  const getRouteName = (idRoute) => {
    const itemIndex = availableRoutes.findIndex(
      (routes) => parseInt(routes.id_route) === idRoute
    );
    return availableRoutes[itemIndex].description;
    // if (itemIndex > 0) {
    // } else {
    //   return "Sin Ruta";
    // }
  };


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    dispatch(setClientsToUpdateRoute(newSelected));
    setSelected(newSelected);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="Clientes"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              headStyles={classes.dataTableheads}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              massiveUpdate={massiveUpdate}
            />
            {loading ? (
              <TableBody>
                <TableRow style={{ padding: 0 }}>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                </TableRow>
                <TableRow style={{ padding: 0 }}>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                </TableRow>
                <TableRow style={{ padding: 0 }}>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id_client);

                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.name}
                        onClick={(event) => handleClick(event, row.id_client)}
                        selected={isItemSelected}
                      >
                        {massiveUpdate && (
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align="center"
                            className={classes.tableCellItem}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        )}

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="center"
                          className={classes.tableCellItem}
                        >
                          {row.id_client}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="center"
                          className={classes.tableCellItem}
                          // onClick={() => handleClickUserName(row.id_client)}
                          // style={{ cursor: "pointer" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellItem}
                        >
                          {getRouteName(row.id_route)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellItem}
                        >
                          {row.city}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellItem}
                        >
                          {row.zip_code}
                        </TableCell>

                        {!massiveUpdate && (
                          <React.Fragment>
                            <TableCell
                              align="center"
                              className={classes.tableCellItem}
                            >
                              {handleState(row.status)}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCellItem}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-around"
                                alignItems="center"
                                className={classes.iconActionsContainer}
                              >
                                <IconButton onClick={() => openViewInfo(row)}>
                                  <MdOutlineRemoveRedEye
                                    className={classes.actionIcons}
                                  />
                                </IconButton>
                                <IconButton onClick={() => openDeleteUser(row)}>
                                  <MdDeleteOutline
                                    className={classes.actionIcons}
                                  />
                                </IconButton>
                                <IconButton onClick={() => openEditUser(row)}>
                                  <FaRegEdit className={classes.actionIcons} />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    openPaymentInvoicesInfo(row.id_client)
                                  }
                                >
                                  <MdPayment className={classes.actionIcons} />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Padding denso"
      />
    </div>
  );
}
