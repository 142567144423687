import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS } from "../../constants/styleConstants";

const RoutesStyles = makeStyles((theme) => ({
  buttonsBox: {
    marginTop: 40,
  },
  addRouteButton: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    marginRight: 142,
    height: 40,
    width: 180,
    borderRadius: 10,
  },
  editRouteButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "left",
    marginRight: 142,
    height: 40,
    width: 200,
    borderRadius: 10,
    textTransform: "uppercase",
  },
  getRouteButton: {
    color: theme.palette.primary.main,
    fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
    fontSize: 16,
    float: "left",
    marginRight: 142,
    height: 40,
    width: 200,
    borderRadius: 10,
    textTransform: "uppercase",
  },
  deleteRouteButton: {
    color: "red",
    borderRadius: 180,
  },
  subtitle: {
    color: "gray",
    marginLeft: 30,
  },
  SkeletonMapSection: {
    width: 220,
    height: 180,
    paddingTop: 80,
    borderRadius: 10,
    marginTop: 30,
  },
  skeletonTitleSection: {
    width: 180,
    height: 5,
    borderRadius: 5,
    marginTop: -20,
  },
  skeletonSubtitleSection: {
    width: 120,
    height: 5,
    borderRadius: 5,
    marginTop: 5,
  },
  skeletonButtonSection: {
    width: 60,
    height: 50,
    borderRadius: 10,
    marginTop: 5,
  },
  skeletonDeleteButtonSection: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    marginLeft:120,
    marginTop:-50
  },
  routesListContainer: {
    paddingTop: 40,
    paddingBottom: 60,
  },
}));

export default RoutesStyles;
