import moment from "moment";

export const formatNumber = (num) => {
  if (typeof number !== "number") {
    num = parseInt(num);
  }
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatDate = (date) => {
  let fecha = new Date(date);
  let day = fecha.getDate();
  let month = fecha.getMonth() + 1;
  let year = fecha.getFullYear();
  if (month < 10) {
    return `${day}-0${month}-${year}`;
  } else {
    return `${day}-${month}-${year}`;
  }
};

export const formatTimestamp = (timestamp) => {
  let date = new Date(timestamp);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;
  hour = (hour < 10 ? "0" : "") + hour;
  min = (min < 10 ? "0" : "") + min;
  sec = (sec < 10 ? "0" : "") + sec;

  var str =
    date.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    min +
    ":" +
    sec;
  return str;
};

export const formatDateToUTC = (date) => {
  return moment(date).utc().format("DD-MM-YYYY HH:mm:SS")
};
