import { FONT_CONSTANTS } from "../../constants/styleConstants";

export const themeTypography = {
  h1: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_EXTRABOLD].join(","),
    fontStyle: `normal`,
    fontSize: 48,
    lineHeight: `54px`,
  },
  h2: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_EXTRABOLD].join(","),
    fontSize: 34,
    fontStyle: `normal`,
    lineHeight: `45px`,
  },
  h3: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontSize: 24,
    fontStyle: `normal`,
    lineHeight: `31px`,
  },
  h4: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),

    fontSize: 34,
    lineHeight: `41px`,
    letterSpacing: `0.25px`,
  },
  h5: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontSize: 24,
    fontStyle: `normal`,
    lineHeight: `32px`,
    letterSpacing: `0`,
  },
  h6: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_MEDIUM].join(","),
    fontSize: 20,
    fontStyle: `normal`,
    lineHeight: `28px`,
    letterSpacing: `0.15px`,
  },
  body1: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `24px`,
    letterSpacing: `1%`,
  },
  body2: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontSize: 12,
    fontStyle: `normal`,
    lineHeight: `20px`,
    letterSpacing: `0.5%`,
  },
  subtitle1: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: 16,
    lineHeight: `24px`,
    letterSpacing: `0.1px`,
  },
  subtitle2: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_SEMIBOLD].join(","),
    fontSize: 14,
    fontStyle: `normal`,
    lineHeight: `17px`,
  },
  //custom
  itemsTypography: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_EXTRABOLD].join(","),
    fontStyle: `normal`,
    fontSize: 18,
    lineHeight: `27px`,
  },
  itemsTableTypography: {
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_EXTRABOLD].join(","),
    fontStyle: `normal`,
    fontSize: 14,
    lineHeight: `21px`,
  },
  numberDashboardItem:{
    fontFamily: [FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD].join(","),
    fontStyle: `normal`,
    fontSize: 32,
    lineHeight: `36px`,
  }
};
