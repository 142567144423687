import axios from "axios";
const { REACT_APP_NAVICK_API_URL } = process.env;

export const insertPaymentInvoice = (payload, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/payments`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };
  return axios(request);
};
export const updatePrintedPaymentInformation = (payload, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "PUT",
    data: payload,
    body: JSON.stringify(payload),
    url: `https://${REACT_APP_NAVICK_API_URL}/payments`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };
  return axios(request);
};

export const getPaymentsByDay = (selectedDate, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: selectedDate,
    url: `https://${REACT_APP_NAVICK_API_URL}/payments/byDay/${selectedDate}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
export const getPaymentsByIdInvoice = (idInvoice, accessToken) => {
  const request = {
    host: `${REACT_APP_NAVICK_API_URL}`,
    method: "GET",
    data: idInvoice,
    url: `https://${REACT_APP_NAVICK_API_URL}/payments/${idInvoice}`,
    headers: {
      "content-type": "application/json",
      token: accessToken,
    },
  };

  return axios(request);
};
