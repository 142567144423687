import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoicesInformation: [],
};

export const invoiceInformationReducer = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setTotalInvoicesInfo: (state, { payload }) => {
      state.invoicesInformation = payload;
    },
  },
});

export const { setTotalInvoicesInfo, setClientDetailInvoices } =
  invoiceInformationReducer.actions;
export const selectTotalInvoices = (state) =>
  state.invoices ? state.invoices.invoicesInformation : null;

export default invoiceInformationReducer.reducer;
