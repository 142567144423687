import { makeStyles } from "@material-ui/core/styles";
import { GiTransparentSlime } from "react-icons/gi";

const PaymentZonesStyles = makeStyles((theme) => ({
    mainContainer: {
        marginLeft: 80,
        width: "100%",
    },
    paymentZoneTitle: {
        color: theme.palette.primary.main,
        marginTop: 30,
    },
    subtitle: {
        marginTop: 40,
        color: theme.palette.primary.dark,
        marginBottom: 51,
        fontWeight: 800,
        lineHeight: "36px",
        fontSize: 24
    },
    subtitle2: {
        fontSize: "36px",
        fontWeight: 700,
        lineHeight: "54px",
        color: "rgba(0,0,0,0.87)",
        marginBottom: 34
    },
    sectionTitle: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "36px",
    },
    sectionTitleBold: {
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "36px",
    },
    totalBold: {
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "48px",
        color: "#000"
    },
    zonesContainer: {
        display: "flex",
        minWidth: 824,
        gap: 40,
        flexFlow: "row wrap",
        marginBottom: 72
    },
    buttonZone: {
        minWidth: 173,
        height: 43,
        backgroundColor: "#EEE9E9",
        borderRadius: 10,
        display: "flex !important",
        flexDirection: "row",
        gap: "11px"
    },
    buttonZoneSelected: {
        minWidth: 173,
        height: 43,
        backgroundColor: "#35A40E99",
        borderRadius: 10,
        display: "flex !important",
        flexDirection: "row",
        gap: "11px"
    },
    rectangleInfoContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "29px",
        marginBottom: 51
    },
    rectangleInfo: {
        minWidth: 247,
        height: 129,
        padding: 22,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#57BD9E",
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)"
    },
    rectangleTitle: {
        color: "#FFF",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px"
    },
    rectangleSubtitle: {

        color: "#FFF",
        fontWeight: 700,
        fontSize: "40px",
        lineHeight: "60px"
    },
    circleCardContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        gap: "40px",
        marginTop: 30,
        marginBottom: 52
    },
    circleCard: {
        width: 127,
        height: 155,
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    },
    circleItem: {
        width: 127,
        height: 127,
        minHeight: 127,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "#008259",
        justifyContent: "center",
    },
    circleItemText: {
        color: "#FFF",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "36px",
    },
    circleBottomText: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "22px",
        color: "#000",
        textAlign: "center"
    },
    inputsContainer: {
        marginTop: 43,
        marginBottom: 36,
        display: "flex",
        flexDirection: "row",
        gap: "38px"
    },
    currencyZoneTotal: {
        fontSize: "48px",
        lineHeight: "72px",
        fontWeight: "700"
    },
    currencyZoneBox: {
        padding: "46px 90px",
        background: "#F3E6E6",
        borderRadius: 10,
        width: "fit-content"
    },
    currencyZoneContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginBottom: "50px"
    },
    inputTextPercentage: {
        width: "60%",
        borderRadius: 6,
        border: "none",
        outline: "none",
        height: 42,
        textAlign: "center",
        background: "transparent",
        color: "white",
        fontWeight: 700,
        fontSize: 18,
        "&::placeholder": {
            color: "white"
        }
    }
}));

export default PaymentZonesStyles;
