import {
  TableCell,
  TableHead,
  TableRow,
  //   TableSortLabel,
  Typography,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const EnhancedTableHead = (props) => {
  //   const { headCells, headStyles, order, orderBy, onRequestSort } = props;
  const { headCells, headStyles, order, orderBy, massiveUpdate } = props;

  //   const createSortHandler = (property) => (event) => {
  //     onRequestSort(event, property);
  //   };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (
            (headCell.id === "check" && !massiveUpdate) ||
            (headCell.id === "estado" && massiveUpdate)
          ) {
            return null;
          } else {
            return (
              <TableCell
                key={headCell.id}
                align="center"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <Typography variant="h6" className={headStyles}>
                  {headCell.label}
                </Typography>
              </TableCell>
            );
          }
        })}
        {!massiveUpdate && (
          <TableCell align="center" padding="normal">
            <Typography variant="h6" className={headStyles}>
              Acciones
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
