import { makeStyles } from "@material-ui/core/styles";

const DeleteUserStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 350,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "row",
  },
  deleteButton: {
    width: 140,
    height: 40,
    borderRadius: 20,
  },
  cancelButton: {
    width: 140,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  cardTitle: {
    marginTop: 40,
    marginBottom: 40,
  },
  lottieAnimation: {},
  cardUserName: {
    fontSize: 22,
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginBottom: 80,
  },
  subtitleText: {
    marginTop: 20,
    fontSize: 22,
  },
  userDeletedTitle: {
    marginBottom: 10,
    color: theme.palette.primary.dark,
  },
  userDeletedSubtitle: {
    marginRight: 10,
    marginLeft: 10,
    color: theme.palette.primary.dark,
  },
  closeIconContainer: {
    float: "right",
    marginTop: -50,
  },
  closeIconContainerMain: {
    float: "right",
    marginTop: -15,
  },
}));

export default DeleteUserStyles;
