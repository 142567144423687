import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceCard from "../../../components/sharedComponents/PricesCard";
import { selectClients } from "../../../store/slices/clients";
import {
  addAmount,
  addProductsQuantity,
  addProductToCart,
  removeAmount,
  removeProductFromCart,
  removeProductsQuantity,
  resetProductsInformation,
  selectClientForInvoice,
  selectPaymentType,
  selectProductsSelected,
  selectTotalProductAmount,
  setPaymentType,
  setSelectedClient,
} from "../../../store/slices/products";
import CreateInvoicesStyles from "./CreateInvoice.styles";
import productImage from "../../../assets/images/ProductImage.png";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
import { formatNumber } from "../../../utils/formatUtils";
import { priceList } from "./data";
const Createinvoice = () => {
  const classes = CreateInvoicesStyles();
  const clients = useSelector(selectClients);
  const selectedUser = useSelector(selectClientForInvoice);
  const [selectedProducts, setselectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const paymentType = useSelector(selectPaymentType);
  const totalAmount = useSelector(selectTotalProductAmount);
  const dispatch = useDispatch();
  const carProducts = useSelector(selectProductsSelected);
  const typePaymentOptions = [
    { idPaymentType: 1, description: "Contado" },
    { idPaymentType: 2, description: "Crédito" },
  ];
  const mapingProducts = (user) => {
    let prodArray = priceList.filter(
      (list) => list.idPriceList === user.id_price_list
    );
    let products = prodArray.map((item) => ({
      ...item,
      quantity: 1,
    }));
    return products;
  };

  const handleSelectedUser = async (user) => {
    if (user) {
      dispatch(resetProductsInformation());
      dispatch(setSelectedClient(user));
      let prods = mapingProducts(user);
      setAvailableProducts(prods);
    }
  };

  const handleSelectProducts = (product) => {
    dispatch(addProductToCart(product));
    dispatch(addAmount(product.price));
    setselectedProducts([...selectedProducts, product]);
  };

  const handleAddProductQuantity = async (productDetail) => {
    dispatch(addAmount(productDetail.price));
    dispatch(addProductsQuantity(productDetail.idArticle));
  };
  const handleRemoveProductQuantity = async (productDetail) => {
    if (totalAmount > 0) {
      dispatch(removeAmount(productDetail.price));
    }
    dispatch(removeProductsQuantity(productDetail.idArticle));
  };

  const handleRemoveProduct = (productDetail) => {
    if (totalAmount > 0) {
      let amountToRemove = productDetail.quantity * productDetail.price;
      dispatch(removeAmount(amountToRemove));
    }
    dispatch(removeProductFromCart(productDetail.idArticle));
  };

  // const handleChangePrice = (id) =>{
  //   const itemIndex = carProducts.clientListToPrint.findIndex(
  //     (prod) => prod.id_client === payload.id_client
  //   );
  //   if (itemIndex < 0) {
  //     state.clientListToPrint = [...state.clientListToPrint, payload];
  //   }
  // }

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={4}>
        <Typography variant="h5">Elige el cliente</Typography>
        <Autocomplete
          id="client-name"
          disableClearable
          defaultChecked={selectedUser.name}
          options={clients}
          getOptionLabel={(client) => client.name}
          className={classes.clientNameInput}
          onChange={(event, value) => handleSelectedUser(value)}
          renderInput={(params) => (
            <TextField {...params} label="Cliente" variant="outlined" />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="h5">Selecciona los productos</Typography>
        <Autocomplete
          id="client-products"
          disableClearable
          options={availableProducts}
          getOptionLabel={(product) => product.idArticle}
          className={classes.clientNameInput}
          onChange={(event, value) => handleSelectProducts(value)}
          renderInput={(params) => (
            <TextField {...params} label="Productos" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <Typography variant="h5" className={classes.paymentTypeText}>
            tipo de pago
          </Typography>
          <Select
            variant="outlined"
            labelId="type-payments"
            value={paymentType}
            onChange={(e) => dispatch(setPaymentType(e.target.value))}
          >
            {typePaymentOptions.map((option) => (
              <MenuItem value={option.idPaymentType}>
                {option.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Typography variant="h5" className={classes.chooseProductsTitle}>
        Detalle de productos
      </Typography>
      {carProducts && carProducts.length > 0 ? (
        carProducts.map((product) => (
          <Grid item xs={12} key={product.idArticle}>
            <PriceCard
              articleName={product.idArticle}
              price={product.price}
              quantityProducts={product.quantity}
              handleAddQuantity={handleAddProductQuantity}
              handleRemoveQuantity={handleRemoveProductQuantity}
              handleRemoveProduct={handleRemoveProduct}
              productDetail={product}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <CenteredContent direction="column">
            <Typography
              variant="h2"
              align="center"
              className={classes.productsDetail}
            >
              Elija sus productos
            </Typography>
            <img
              src={productImage}
              alt="prd-img"
              className={classes.imageDisabled}
            />
          </CenteredContent>
        </Grid>
      )}

      {carProducts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.totalInvoiceAmountText}>
            Total de la factura
          </Typography>
          <Typography
            variant="h2"
            className={classes.totalInvoiceAmountTNumber}
          >
            ${totalAmount && formatNumber(totalAmount)} MXN
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Createinvoice;
