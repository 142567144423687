export const FONT_CONSTANTS = {
  FONT_FAMILY_POPPINS_NORMAL: "Poppins",
  FONT_FAMILY_POPPINS_BOLD: "PoppinsBold",
  FONT_FAMILY_POPPINS_EXTRABOLD: "PoppinsExtraBold",
  FONT_FAMILY_POPPINS_MEDIUM: "PoppinsMedium",
  FONT_FAMILY_POPPINS_SEMIBOLD: "PoppinsSemiBold",
};
export const COLOR_CONSTANTS = {
  MAIN_GREEN: "#159E60",
  SECONDARY_GREEN: "#00917C",
  LIGHT_GREEN: "#5FB572",
  DARK_GREEN: "#184D47",
  GREEN_60: "#035956",
  GREEN_30: "#A5F0C5",
  GREEN_10: "#A0D8C0",
  MAIN_GRAY: "#343D46",
  GRAY_50: "#343D4675",
  GRAY_40: "#fafafa",
  GRAY_30: "#BDBDBD",
  EXTRA_LIGHT: "#BAE5CC",
  APPLE_GREEN: "rgba(186, 229, 204, 0.37)",
  TEXT_DARK: "#020202",
  TEXT_DARK_MEDIUM: "#2C3130",
  TEXT_DARK_DISABLED: "rgba(2, 2, 2, 0.51)",
  TEXT_GRAY: "rgba(0, 0, 0, 0.48)",
  WHITE: "#FFFFFF",
  INACTIVE_ERROR: "#D83434",
};
