import { makeStyles } from "@material-ui/core/styles";

const ClientCardStyles = makeStyles((theme) => ({
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  mainContainer: {
    height: 60,
    width: 300,
    borderRadius: 10,
    padding: 10,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    marginBottom: 5,
    justifyContent: "space-around",
    animation: "$fadeIn .5s ease-in-out",
  },
  cardTitle: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 7,
  },
  remainingPayment: {
    marginTop: 10,
    marginLeft: 70,
  },
  removeIcon: {
    display: "flex",
    float: "left",
  },
  textContainer: {
    width: 250,
  },
  iconContainer: {
    width: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

}));

export default ClientCardStyles;
