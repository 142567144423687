import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close, DeleteForever } from "@material-ui/icons";
import React from "react";
import AnimationContent from "../../sharedComponents/AnimationContent";
import DeleteAnimation from "../../../assets/lottieFiles/DeleteAnimation.json";
import loadingAnimation from "../../../assets/lottieFiles/loaderAnimation.json";

import DialogBodyModalStyles from "./DialogBodyModal.styles";
const DialogBodyModal = (props) => {
  const {
    userInformation,
    titleDialog,
    subtitleDialog,
    handleCancel,
    handleDelete,
    loading,
    success,
  } = props;

  const classes = DialogBodyModalStyles();
  const isCentered = loading || success;
  return (
    <Box
      className={classes.mainContainer}
      display={isCentered && "flex"}
      alignItems={isCentered && "center"}
      justifyContent={isCentered && "center"}
    >
      {loading ? (
        <Box>
          <AnimationContent
            animationFile={success ? DeleteAnimation : loadingAnimation}
            width={120}
            height={120}
          />
          <Typography
            align="center"
            variant="subtitle2"
            className={classes.animationTitle}
          >
            {success ? "Usuario Eliminado" : "Eliminando usuario"}
          </Typography>
        </Box>
      ) : (
        <React.Fragment>
          <div
            className={classes.closeIconContainerMain}
            onClick={handleCancel}
          >
            <IconButton>
              <Close />
            </IconButton>
          </div>
          <Typography variant="h6" align="center" className={classes.cardTitle}>
            {titleDialog}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.cardUserName}
          >
            {subtitleDialog}
          </Typography>
          <Box className={classes.buttonsContainer}>
            <Button
              startIcon={<DeleteForever />}
              variant="contained"
              color="primary"
              className={classes.deleteButton}
              onClick={() => handleDelete(userInformation.id_user, 0)}
            >
              Eliminar
            </Button>
            <Button
              color="secondary"
              className={classes.cancelButton}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default DialogBodyModal;
