import { Box } from "@material-ui/core";
import React from "react";

const CenteredContent = ({ children, className,direction, style }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={direction && direction}
      className={className}
      style={style && style}
    >
      {children}
    </Box>
  );
};

export default CenteredContent;
