import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "../store/slices/user";

const useGetFolioNumber = () => {
  const idUser = useSelector(selectUserId);
  const date = moment().format("DDMMyyyyHHmmss");
  const [idPaymentFormat, setIdPaymentFormat] = useState("");
  useEffect(() => {
    setIdPaymentFormat(`A${idUser}-${date}`);
  }, [date, idUser]);
  return idPaymentFormat;
};

export default useGetFolioNumber;
