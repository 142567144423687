import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dailyPayments: [],
  filterDailyPayments: [],
};

export const PaymentsInformationReducer = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setDailyPayments: (state, { payload }) => {
      state.dailyPayments = payload;
    },
    setFilterDailyPayments: (state, { payload }) => {
      state.filterDailyPayments = payload;
    },
    resetDailyPayments: (state) => (state = initialState),
  },
});

export const {
  setDailyPayments,
  setFilterDailyPayments,
  resetDailyPayments,
} = PaymentsInformationReducer.actions;

export const selectDailyPayments = (state) =>
  state.payments ? state.payments.dailyPayments : null;

export const selectFilteredDailyPayments = (state) =>
  state.payments ? state.payments.filterDailyPayments : null;

export default PaymentsInformationReducer.reducer;
