import { makeStyles } from "@material-ui/core/styles";
import { FONT_CONSTANTS,COLOR_CONSTANTS } from "../../../constants/styleConstants";

const createRouteStyles = makeStyles((theme) => ({

    createRouteButton:{//colores de Tema
        backgroundColor: theme.palette.primary.main,
        color:"white",
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
        fontSize: 18,
        float: "left",
        marginRight: 142,
        height: 55,
        width: 155,
        borderRadius: 10,
        marginTop:25,
      },
      cancelCreateRouteButton:{
        backgroundColor: "white",
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
        fontSize: 18,
        float: "left",
        marginRight: 142,
        height: 55,
        width: 155,
        borderRadius: 10,
        marginTop:25,
        color:COLOR_CONSTANTS.DARK_GREEN
      },
      modalStyle:{
        backgroundColor:theme.palette.primary.white,
        width:567,
        height:400,
        borderRadius:10,
        padding:40
      },
      descriptionInputStyle:{
        width:"100%",
        height:40 
      },
      textFieldInputStyle:{
        width:"100%",
        marginTop:30, 
      },
      createRouteTitle:{
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_POPPINS_BOLD,
        color:theme.palette.primary.dark,
        textAlign:"center",
        fontSize:36
      },
      createRouteSubTitle:{
        color:COLOR_CONSTANTS.DARK_GREEN,
      },
      animationTitle:{
        marginBottom: 10,
        color: theme.palette.primary.dark,
      }
 
}));

export default createRouteStyles;
