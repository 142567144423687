import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React from "react";
import EnhancedTableHead from "../DataTable/EnhancedTableHead";
import UsersDataTableStyles from "./UsersDataTable.styles";
import { MdOutlineRemoveRedEye, MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectAvailableRoutes } from "../../../store/slices/routes";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const UsersDataTable = (props) => {
  const {
    rows,
    headCells,
    openViewUserInformation,
    openDeleteUser,
    openUpdateUserInformation,
  } = props;

  const classes = UsersDataTableStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const availableRoutes = useSelector(selectAvailableRoutes);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getRouteNameById = (idRoute) => {
    const route = availableRoutes.filter(
      (route) => parseInt(route.id_route) === parseInt(idRoute)
    );
    return route[0].description
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleState = (state) => {
    if (state === 1) {
      return <Chip label="Activo" className={classes.activeChipState} />;
    } else {
      return <Chip label="Inactivo" className={classes.inactiveChipState} />;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="Clientes"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              headStyles={classes.dataTableheads}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.nombre);

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id_user}
                      selected={isItemSelected}
                    >
                      <TableCell
                        align="center"
                        className={classes.tableCellItem}
                      >
                        {row.username}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellItem}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellItem}
                      >
                        {/* {row.id_route} */}
                        {getRouteNameById(row.id_route)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellItem}
                      >
                        {handleState(row.status)}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.tableCellItem}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-around"
                          alignItems="center"
                          className={classes.iconActionsContainer}
                        >
                          <IconButton
                            onClick={() => openViewUserInformation(row)}
                          >
                            <MdOutlineRemoveRedEye
                              className={classes.actionIcons}
                            />
                          </IconButton>

                          <IconButton onClick={() => openDeleteUser(row)}>
                            <MdDeleteOutline className={classes.actionIcons} />
                          </IconButton>

                          <IconButton
                            onClick={() => openUpdateUserInformation(row)}
                          >
                            <FaRegEdit className={classes.actionIcons} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Padding denso"
      />
    </div>
  );
};

export default UsersDataTable;
