import { makeStyles } from "@material-ui/core/styles";

const InvoicesDatatableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  icons:{
      fontSize:20, 
      color:theme.palette.primary.dark
  },
  noData:{
    color:theme.palette.primary.text_dark_disabled
  },
  noDataBody:{
    height:200
  }
}));

export default InvoicesDatatableStyles;
