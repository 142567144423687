import React from "react";
import Lottie from "react-lottie";

const AnimationContent = (props) => {
  const { animationFile, styles, width, height } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      className={styles && styles}
      options={defaultOptions}
      height={height ? height : 300}
      width={width ? width : 300}
      isStopped={false}
      isPaused={false}

    />
  );
};

export default AnimationContent;
