import { makeStyles } from "@material-ui/core/styles";

const AuditStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 80,
    width: "100%",
  },
  auditTitle: {
    color: theme.palette.primary.main,
    marginTop: 30,
  },
  subtitle: {
    marginTop: 40,
    color: theme.palette.primary.dark,
    marginBottom:20
  },
  routeDropdown: {
    width: 220,
  },
  createPDFButton:{
    marginTop:50,
    marginBottom:10
  }
}));

export default AuditStyles;
