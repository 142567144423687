import { makeStyles } from "@material-ui/core/styles";
const InvoicesPaymentsInformationStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "90%",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: 20,
    paddingLeft:100,
    overflowX:"hidden" ,
    overflowY:"auto" 
  },
  headerSection: {
    marginTop: 50,
    marginBottom: 5,
  },
  headerTitleSection: {
    color: theme.palette.primary.main,
    marginTop: 50,
  },
  clientName: {
    color: theme.palette.primary.text_dark_disabled,
  },
  clientInformationContainer: {
    width: 1250,
  },
  closeIcon: {
    float: "right",
    fontSize: 18,
    marginTop: 10,
    cursor: "pointer",
  },
}));

export default InvoicesPaymentsInformationStyles;
